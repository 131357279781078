import {Skeleton} from "@nextui-org/skeleton";

export default function LapseReportWidget(
    {
        isLoading,
        complianceScore,
        documentPercentInCompliance,
        documentsOutOfCompliance,
        unitsPercentInCompliance,
        unitsOutOfCompliance,
        daysPercentInCompliance,
        daysOutOfCompliance,
    }
) {

    return (
        <>
            <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                {/*TODO: OutOfScope*/}
                {/*<div class={'h-auto border-solid border-1 border-gray rounded-lg px-6 py-6 w-full'}>*/}
                {/*    <div className="flex flex-col mb-4">*/}
                {/*        <div className="h-48 flex justify-center">*/}
                {/*            <img*/}
                {/*                src={`${window.assetUrl}image/lapse-report/optimus.svg`}*/}
                {/*                width={182}*/}
                {/*                height={100}*/}
                {/*                alt="icon"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        <div className={'flex justify-center h-28 mt-6 gap-4'}>*/}
                {/*            <div className={'text-5xl font-bold text-op-highlight'}>{complianceScore} %</div>*/}
                {/*            <div className={'text-op-main text-bold pt-4 text-3xl'}>Good</div>*/}
                {/*        </div>*/}
                {/*        <div className={'text-center h-4.5'}>*/}
                {/*            <div className={'text-op-main text-bold text-2xl'}>Optimus Compliance Score</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div class={'h-auto border-solid border-1 border-gray rounded-lg px-6 py-6 w-full'}>
                    <div className="flex flex-col mb-4">
                        <div className="flex justify-center">
                            <img
                                src={`${window.assetUrl}image/lapse-report/docs.svg`}
                                width={65}
                                height={65}
                                alt="icon"
                            />
                        </div>
                        <div className="flex">
                            <div className={'text-4xl font-bold text-op-main w-100 text-center pt-4 mt-8'}>Documents
                            </div>
                        </div>
                        <Skeleton isLoaded={!isLoading}
                                  className={`${isLoading ? 'animate-pulse h-[40px] w-[60%] mb-10' : ''} bg-skeleton rounded-lg dark:bg-gray-700 mx-auto mt-6`}>
                            <div className={'flex justify-center h-28 gap-4'}>
                                <div className={'text-5xl font-bold text-op-highlight'}>{documentPercentInCompliance}%
                                </div>
                                <div className={'text-op-main text-bold pt-4 text-3xl'}>in Compliance</div>
                            </div>
                        </Skeleton>

                        <div className={'text-center h-4.5'}>
                            <div className={'text-bold'}>
                                <Skeleton isLoaded={!isLoading}
                                          className={`${isLoading ? 'animate-pulse h-[20px] w-[60%]' : ''} bg-skeleton rounded dark:bg-gray-700  mb-4 mx-auto`}>
                                    <span className={'text-op-highlight pr-2'}>{documentsOutOfCompliance}</span>
                                    <span className={'text-op-main text-2xl'}>Documents out of Compliance</span>
                                </Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class={'h-auto border-solid border-1 border-gray rounded-lg px-6 py-6 w-full'}>
                    <div className="flex flex-col mb-4">
                        <div className="flex justify-center">
                            <img
                                src={`${window.assetUrl}image/lapse-report/truck.svg`}
                                width={95}
                                height={95}
                                alt="icon"
                            />
                        </div>
                        <div className="flex">
                            <div className={'text-4xl font-bold text-op-main w-100 text-center'}>Units</div>
                        </div>
                        <Skeleton isLoaded={!isLoading}
                                  className={`${isLoading ? 'animate-pulse h-[40px] w-[60%] mb-10' : ''} bg-skeleton rounded-lg dark:bg-gray-700 mx-auto mt-6`}>
                            <div className={'flex justify-center h-28 mt-6 gap-4'}>
                                <div className={'text-5xl font-bold text-op-highlight'}>{unitsPercentInCompliance}%
                                </div>
                                <div className={'text-op-main text-bold pt-4 text-3xl'}>in Compliance</div>
                            </div>
                        </Skeleton>
                        <div className={'text-center h-4.5'}>
                            <div className={'text-bold'}>
                                <Skeleton isLoaded={!isLoading}
                                          className={`${isLoading ? 'animate-pulse h-[20px] w-[60%]' : ''} bg-skeleton rounded-lg dark:bg-gray-700  mb-4 mx-auto`}>
                                    <span className={'text-op-highlight pr-2'}>{unitsOutOfCompliance}</span>
                                    <span className={'text-op-main text-2xl'}>Units out of Compliance</span>
                                </Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class={'h-auto border-solid border-1 border-gray rounded-lg px-6 py-6 w-full'}>
                    <div className="flex flex-col mb-4">
                        <div className="flex justify-center">
                            <img
                                src={`${window.assetUrl}image/lapse-report/calendar.svg`}
                                width={67}
                                height={67}
                                alt="icon"
                            />
                        </div>
                        <div className="flex">
                            <div className={'text-4xl font-bold text-op-main w-100 text-center pt-3 mt-8'}>Days</div>
                        </div>
                        <Skeleton isLoaded={!isLoading}
                                  className={`${isLoading ? 'animate-pulse h-[40px] w-[60%] mb-10' : ''} bg-skeleton rounded-lg dark:bg-gray-700 mx-auto mt-6`}>
                            <div className={'flex justify-center h-28 mt-6 gap-4'}>
                                <div className={'text-5xl font-bold text-op-highlight'}>{daysPercentInCompliance}%</div>
                                <div className={'text-op-main text-bold pt-4 text-3xl'}>in Compliance</div>
                            </div>
                        </Skeleton>
                        <div className={'text-center h-4.5'}>
                            <div className={'text-bold'}>
                                <Skeleton isLoaded={!isLoading}
                                          className={`${isLoading ? 'animate-pulse h-[20px] w-[60%]' : ''} bg-skeleton rounded-lg dark:bg-gray-700  mb-4 mx-auto`}>
                                    <span className={'text-op-highlight pr-2'}>{daysOutOfCompliance}</span>
                                    <span className={'text-op-main text-2xl'}>Days out of Compliance</span>
                                </Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
