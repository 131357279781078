import HttpClient from "../http-client";

const RESOURCE = '/lapse-report'

export default {
    async run({startDate, endDate}) {
        const endpoint = `${RESOURCE}/run`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async kpis({startDate, endDate, previousReportId}) {
        const endpoint = `${RESOURCE}/kpis`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            previous_lapse_report_id: previousReportId,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async regenerate({startDate, endDate}) {
        const endpoint = `${RESOURCE}/regenerate`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async documentsInAndOutOfCompliance({startDate, endDate, documentIds = [], ignoreGroupings = 0, previousReportId, key}) {
        const endpoint = `${RESOURCE}/charts/documents-in-out-compliance`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            documentIds,
            ignore_groupings: ignoreGroupings,
            previous_lapse_report_id: previousReportId,
            key,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async unitsInAndOutOfCompliance({startDate, endDate, units = [], ignoreGroupings = 0, previousReportId, key}) {
        const endpoint = `${RESOURCE}/charts/units-in-out-compliance`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            units,
            ignore_groupings: ignoreGroupings,
            previous_lapse_report_id: previousReportId,
            key,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async daysInAndOutOfCompliance({startDate, endDate, previousReportId}) {
        const endpoint = `${RESOURCE}/charts/days-in-out-compliance`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            previous_lapse_report_id: previousReportId,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async highAlertDocuments({startDate, endDate, previousReportId}) {
        const endpoint = `${RESOURCE}/charts/high-alert-documents`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            previous_lapse_report_id: previousReportId,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async daysOutOfCompliance({startDate, endDate, previousReportId}) {
        const endpoint = `${RESOURCE}/charts/days-out-of-compliance`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            previous_lapse_report_id: previousReportId,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async share({startDate, endDate}) {
        const endpoint = `${RESOURCE}/share`;
        const body = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };

        const response = HttpClient.patch(endpoint, body);

        return response;
    },
    async findByHash({hash}) {
        const endpoint = `${RESOURCE}/share/${hash}`;

        const response = HttpClient.get(endpoint);

        return response;
    },
    async saveReport({
        startDate,
        endDate,
        remarks,
        docFilters,
        dicFilters,
        uocFilters,
        uicFilters,
        occDocumentFilters,
        occUnitFilters,
        highAlertUnitFilters,
    }) {
        const endpoint = `${RESOURCE}/save-report`;
        const body = {
            start_date: startDate,
            end_date: endDate,
            remarks,
            doc_filters: docFilters,
            dic_filters: dicFilters,
            uoc_filters: uocFilters,
            uic_filters: uicFilters,
            occ_document_filters: occDocumentFilters,
            occ_unit_filters: occUnitFilters,
            high_alert_unit_filters: highAlertUnitFilters,
        };

        const response = HttpClient.post(endpoint, body);

        return response;
    },
    async getPreviousReports() {
        const endpoint = `${RESOURCE}/previous-reports`;

        const response = HttpClient.get(endpoint);

        return response;
    },
    async loadPreviousReport(reportId) {
        const endpoint = `${RESOURCE}/previous-reports/${reportId}`;

        const response = HttpClient.get(endpoint);

        return response;
    },
    async deletePreviousReport(reportId) {
        const endpoint = `${RESOURCE}/previous-reports/${reportId}`;

        const response = HttpClient.delete(endpoint);

        return response;
    },
    async highAlertUnits({
         startDate,
         endDate,
        previousReportId,
        filters,
     }) {
        const endpoint = `${RESOURCE}/high-alert-units`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            previous_lapse_report_id: previousReportId,
            ...filters,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async documentsMeasured({
        startDate,
        endDate,
        unitId,
        unitType,
        previousReportId,
     }) {
        const endpoint = `${RESOURCE}/high-alert-units/documents-measured`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            unit_id: unitId,
            previous_lapse_report_id: previousReportId,
            unit_type: unitType,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async outOfComplianceTimeline({
        startDate,
        endDate,
        unitId,
        unitType,
        previousReportId,
    }) {
        const endpoint = `${RESOURCE}/high-alert-units/out-of-compliance-timeline`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            unit_id: unitId,
            unit_type: unitType,
            previous_lapse_report_id: previousReportId,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
    async outOfComplianceCalendar({
        startDate,
        endDate,
        unitId,
        unitType,
    }) {
        const endpoint = `${RESOURCE}/high-alert-units/out-of-compliance-calendar`;
        const queryParams = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            unit_id: unitId,
            unit_type: unitType,
        };

        const response = HttpClient.get(endpoint, {queryParams});

        return response;
    },
};
