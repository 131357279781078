import React, {PureComponent, useState, useEffect, useMemo} from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Legend,
    Bar,
    Rectangle,
    Brush,
    ReferenceLine,
} from 'recharts';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tab,
    Tabs,
    CheckboxGroup,
    Skeleton,
    Spinner
} from "@nextui-org/react";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import moment from "moment";
import {getLocalTimeZone} from "@internationalized/date";
import {debounce, sumBy, map, round, isEmpty} from "lodash";
import DocumentTypeFilter, {DOCUMENT_FILTERS} from "../_shared/DocumentTypeFilter";
import useShareReport from "../_hooks/useShareReport";
import {previousReportIdAtom, filtersAtom} from "../_atoms/lapseReportAtom";
import {useAtomValue, useAtom} from "jotai";

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length > 0) {
        const {
            label = null,
            log_date,
            driver_out_of_compliance,
            truck_out_of_compliance,
            out_of_compliance
        } = payload[0]?.payload ?? null;

        return (
            <div
                className="custom-tooltip text-tiny mb-2"
                style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                }}
            >
                <p className="label font-semibold mb-2">{label}</p>
                <p className={'mb-2'}>{`Date: ${label ?? log_date}`}</p>
                <p className={'mb-2'}>{`Driver Docs: ${driver_out_of_compliance}`}</p>
                <p className={'mb-2'}>{`Truck Docs: ${truck_out_of_compliance}`}</p>
                <p>{`Total Docs: ${out_of_compliance}`}</p>
            </div>
        );
    }

    return null;
};

let fetchDocsTimeout = null;

export default function DocumentsOutOfComplianceChart({rangeDates, isGenerating}) {
    const [selected, setSelected] = useState("total");
    const [documentsChart, setDocumentsChart] = useState(null);
    const [total, setTotal] = useState(0);
    const [outOfCompliance, setOutOfCompliance] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [previousReportId] = useAtom(previousReportIdAtom)
    const [filters, setFilters] = useAtom(filtersAtom)

    const mapResponse = (documents) => {
        setTotal(sumBy(map(documents, item => {
            item.total = !!item.total ? Number(item.total) : 0;

            return item;
        }), 'total'));
        setOutOfCompliance(sumBy(map(documents, item => {
            item.out_of_compliance = !!item.out_of_compliance ? Number(item.out_of_compliance) : 0;

            return item;
        }), 'out_of_compliance'));
        setDocumentsChart(documents);
    }

    const fetchDocumentsCharts = async (docs, previousReportId) => {
        setIsLoading(true);

        const {response: documents} = await LapseReportApi.documentsInAndOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            documentIds: docs ?? [],
            previousReportId,
            key: 'doc_response',
        });

        mapResponse(documents);

        setIsLoading(false);
    };

    useEffect(() => {
        if (isGenerating === false) {
            fetchDocumentsCharts(filters.doc_filters);
        }
    }, [isGenerating]);

    useEffect(() => {
        if (previousReportId) fetchDocumentsCharts(filters.doc_filters, previousReportId);
    }, [previousReportId]);


    const onDocumentChange = (docs) => {
        setFilters({...filters, doc_filters: docs})

        if (fetchDocsTimeout) {
            clearTimeout(fetchDocsTimeout)
        }

        fetchDocsTimeout = setTimeout(() => {
            fetchDocumentsCharts(docs)
        }, 1000)
    };

    const calculatePercentage = () => {
        const percentage = ((outOfCompliance / total) * 100).toFixed(2);

        if (isNaN(percentage)) {
            return 0;
        }

        return percentage;
    }

    return (<>
        <div className="mb-3">
            <p className="mb-4 text-3xl font-bold text-blue-950">Documents out of Compliance</p>
        </div>
        <div className={"flex flex-row items-end mb-2 gap-6"}>
            <div className="w-4/5 border-solid border-1 border-gray rounded-lg px-6 py-6">
                <span>Total number of documents out of compliance:</span>
                <div className="flex flex-row">
                    {(isLoading || isGenerating) && (
                        <>
                            <Skeleton isLoaded={!(isLoading || isGenerating)} class={'h-[20px] w-[95px] mr-[60px] bg-skeleton rounded-lg'}></Skeleton>
                            <Skeleton isLoaded={!(isLoading || isGenerating)} class={'h-[20px] w-[95px] bg-skeleton rounded-lg'}></Skeleton>
                        </>
                    )}
                    {!(isLoading || isGenerating) && (
                        <>
                            <span className="font-semibold text-2xl w-56">{outOfCompliance} / {total}</span>
                            <span className="font-semibold text-2xl">{calculatePercentage()}%</span>
                        </>
                    )}
                </div>
            </div>
            <div className="w-1/4">
                <Tabs
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                    size={"lg"}
                    color={"primary"}
                    classNames={{
                        base: "w-100",
                        tabList: "w-100"
                    }}
                >
                    <Tab key="total" title="Total" className={'text-lg'}></Tab>
                    <Tab key="segmented" title="Segmented" className={'text-lg'}></Tab>
                </Tabs>
                <DocumentTypeFilter onChange={onDocumentChange} value={filters.doc_filters}/>
            </div>
        </div>
        { (isLoading || isGenerating) && (
            <Skeleton isLoaded={false}
                      className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '100%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                    </ResponsiveContainer>
                </div>
            </Skeleton>
        )}
        {!(isLoading || isGenerating) && (
            <div className="flex flex-row border-solid border-1 border-gray rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '100%'}}>
                    <ResponsiveContainer width="97%" height={400}>
                        <BarChart data={documentsChart} barGap={2}>
                            <CartesianGrid stroke="#e6e6e6" vertical={false}/>
                            <XAxis
                                angle={-30}
                                dataKey="log_date"
                                tick={{fontSize: 14}}
                                tickMargin={10}
                                tickSize={10}
                                height={100}
                            />
                            <YAxis/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>
                            <ReferenceLine y={0} stroke="#000"/>
                            {selected === 'segmented' && (
                                <>
                                    <Bar dataKey="driver_out_of_compliance" name="Driver" stackId="total"
                                         fill="#582AC6"/>
                                    <Bar dataKey="truck_out_of_compliance" name="Truck" stackId="total" fill="#57BADF"/>
                                </>
                            )}
                            {selected === 'total' && (
                                <>
                                    <Bar dataKey="out_of_compliance" name="Total" stackId="total" fill="#57BADF"/>
                                </>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )}
    </>);
}
