import {Button, Divider, Modal, ModalContent, ModalBody, ModalFooter, ModalHeader} from "@nextui-org/react";
import React from "react";
import {VscShare} from "react-icons/vsc";
import {AiOutlineCopy} from "react-icons/ai";

export default function ShareModal({isOpen, onOpen, onOpenChange, shareUrl}) {

    const onCopyUrl = () => {
        window.navigator.clipboard.writeText(shareUrl)

        toastr.success("Share Link Copied to Clipboard", {timeOut: 1000})
    }

    return (<>
        <Modal
            className={'max-w-[65em] right-[-8em]'}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={false}
            isKeyboardDismissDisabled={true}
            classNames={{
                closeButton: "border-none bg-white mt-4 mr-3",
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="text-2xl font-bold text-blue-950 pt-3 mt-3 pb-0">Share Report</ModalHeader>
                        <Divider className={'mb-3'}/>
                        <ModalBody>
                            <div className={'w-100'}>
                                <span className={'text-bold'}>Share Url: </span>
                                <span>{shareUrl}</span>
                            </div>
                        </ModalBody>
                        <Divider className={'mb-3'}/>
                        <ModalFooter className={'justify-between'}>
                            <Button color="default"
                                    className={'float-left border border-gray text-lg h-[35px] drop-shadow-none'}
                                    variant="light"
                                    onPress={onClose}
                            >
                                Close
                            </Button>
                            <Button
                                color="success"
                                className={'border-none border-gray text-white text-lg h-[35px] drop-shadow-none'}
                                startContent={
                                    <AiOutlineCopy size={40} color={'white'}/>
                                }
                                onPress={onCopyUrl}>
                                Copy Url
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>);
}
