import {useMemo, useState, useEffect} from "react";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import {map, sumBy} from "lodash";
import {UNIT_TYPES} from "../../../_constants/unit-type";

export default function useUnitComplianceChart({rangeDates, isGenerating, setFilter, previousReport, key, defaultFilter}) {
    const [units, setUnits] = useState(UNIT_TYPES);
    const [data, setData] = useState({
        chart: [],
        total: 0,
        out_of_compliance: 0,
        in_compliance: 0,
        out_percentage: 0,
        in_percentage: 0,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (defaultFilter) {
            setUnits(defaultFilter)
            setFilter(defaultFilter)
        }
    }, [defaultFilter]);

    const fetchUnitCharts = async (units) => {
        setIsLoading(true);

        const {response: chart} = await LapseReportApi.unitsInAndOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            units: units ?? [],
            previousReportId: previousReport,
            key,
        });

        const total = sumBy(chart, 'total') ?? 0;
        const out_of_compliance = sumBy(map(chart, (item) => {
            item.out_of_compliance = Number(item.out_of_compliance);

            return item;
        }), 'out_of_compliance');
        const in_compliance = sumBy(map(chart, (item) => {
            item.in_compliance = Number(item.in_compliance);

            return item;
        }), 'in_compliance');
        let out_percentage = ((out_of_compliance / total) * 100).toFixed(2);
        let in_percentage = ((in_compliance / total) * 100).toFixed(2);

        if (isNaN(out_percentage)) {
            out_percentage = 0;
        }

        if (isNaN(in_percentage)) {
            in_percentage = 0;
        }

        setData({
            chart,
            total,
            out_of_compliance,
            in_compliance,
            out_percentage,
            in_percentage,
        });

        setIsLoading(false);
    }

    const onUnitChange = (data) => {
        setUnits(data);
        fetchUnitCharts(data);
        setFilter(data);
    }

    useEffect(() => {
        if (isGenerating === false) {
            fetchUnitCharts(units);
        }

        setFilter(units)
    }, [isGenerating]);

    useEffect(() => {
        if (previousReport) fetchUnitCharts(units);
    }, [previousReport]);

    return {
        data,
        onUnitChange,
        units,
        isLoading,
    };
}
