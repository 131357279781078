import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    Tooltip,
    YAxis,
    XAxis,
    Bar,
} from 'recharts';
import {Skeleton} from "@nextui-org/skeleton";
import React from "react";

export default function HighAlertDocumentsChart({data, isGenerating}) {
    return (
        <>
            <div className="mb-3">
                <p className="mb-4 text-3xl font-bold text-blue-950">High Alert Documents (Most out of Compliance)</p>
            </div>
            <div className="w-full h-auto border-solid border-1 border-gray rounded-lg px-6 py-6">
                {isGenerating && (
                    <>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                        <Skeleton isLoaded={false} className={'animate-pulse w-[25rem] h-[20px] mb-3 bg-skeleton rounded-lg'}></Skeleton>
                    </>
                )}
                {
                    !isGenerating && data && data.map(item =>
                        (<div className="flex flex-row">
                            <p className="w-[20rem]">{item.name}:</p>
                            <p className="font-semibold">{item.count} / {item.total}</p>
                        </div>)
                    )
                }
            </div>
            { (isGenerating) && (
                <Skeleton isLoaded={false}
                          className="animate-pulse w-full h-[27rem] border-solid border-1 border-gray bg-skeleton rounded-lg px-6 py-6 mt-3">
                    <div style={{width: '100%'}}>
                        <ResponsiveContainer width="100%" height="100%">
                        </ResponsiveContainer>
                    </div>
                </Skeleton>
            )}
            {!isGenerating && (
                <div className="w-full h-[32rem] border-solid border-1 border-gray rounded-lg px-6 py-6 mt-3">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} barGap={2} layout="vertical" margin={{left: 25}}>
                            <CartesianGrid stroke="#e6e6e6" vertical={false}/>
                            <XAxis
                                type="number" // X axis for vertical bar chart represents numerical values
                                tick={{fontSize: 14}}
                            />
                            <YAxis
                                type="category" // Y axis represents the categories for a vertical bar chart
                                dataKey="name" // Key for categorical data
                                tick={{fontSize: 14}}
                                tickMargin={12}
                                tickSize={10}
                                width={100}
                            />
                            <Tooltip/>
                            <Bar dataKey="count" name={'Unavailable'} fill="#0060ff"/>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )}
        </>
    );
}
