import React from "react";
import HeroSection from "./HeroSection";
import FeaturesSection from "./FeaturesSection";
import BenefitsSection from "./BenefitsSection";
import ServicesSection from "./ServicesSection";
import ContactForm from "./ContactForm";

const Landing = () => {
  return (
    <div className="min-h-screen bg-white">
      <HeroSection />
      <FeaturesSection />
      <BenefitsSection />
      <ServicesSection />
      <ContactForm />
    </div>
  );
};

export default Landing;
