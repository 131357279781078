import {Skeleton} from "@nextui-org/skeleton";
import React from "react";

export default function SkeletonTable({rows = 4}) {
    const srows = [];

    while (rows--) {
        srows.push(
            <div className="flex gap-2 mt-3">
                <Skeleton isLoaded={false} class={'h-[25px] w-1/4 bg-skeleton rounded-lg'}></Skeleton>
                <Skeleton isLoaded={false} class={'h-[25px] w-1/4 bg-skeleton rounded-lg'}></Skeleton>
                <Skeleton isLoaded={false} class={'h-[25px] w-1/4 bg-skeleton rounded-lg'}></Skeleton>
                <Skeleton isLoaded={false} class={'h-[25px] w-1/4 bg-skeleton rounded-lg'}></Skeleton>
            </div>
        );
    }

    return (<>
        <Skeleton isLoaded={false} class={'h-[35px] w-full bg-skeleton rounded-lg'}></Skeleton>
        {srows.map(item => item)}
    </>)
}
