import React, {useEffect, useRef} from "react";
import moment from "moment/moment";
import CalHeatmap from 'cal-heatmap';
import Legend from 'cal-heatmap/plugins/Legend';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import 'cal-heatmap/cal-heatmap.css';

export default function CalendarHeatmap({days, year, max, range, start, selected, label, domain = null, hasLegend = true}) {
    const heatmapContainerRef = useRef(null);
    const isPainted = useRef(false);
    console.log(start);

    useEffect(() => {
        if (heatmapContainerRef.current && !isPainted.current) {
            const calendar = new CalHeatmap();

            let plugins = [];

            if (hasLegend) {
                plugins = [
                    [
                        Legend,
                        {
                            label,
                            width: 400,
                            itemSelector: '#calendar-legend'
                        },
                    ],
                ];
            }

            const tooltipUnit = (value, date) => {
                let unitTitle = null;

                if (selected === 'documents') {
                    unitTitle = `Total Documents: ${value ?? 0}`
                }

                if (selected === 'units') {
                    unitTitle = `Total Units: ${value ?? 0}`
                }

                if (selected === 'days') {
                    unitTitle = value === 1 ? 'Out of Compliance' : 'In Compliance'
                }

                return `
                <div>Date: ${date}</div>
                <div>${unitTitle}</div>
            `;
            };

            setTimeout(() => {
                calendar.paint({
                        itemSelector: heatmapContainerRef.current,
                        date: {start},
                        range: range ?? 12,
                        domain: {
                            type: 'month',
                            sort: 'asc',
                            gutter: 10
                        },
                        subDomain: {type: "day", radius: 2, width: 15, height: 15},
                        data: {
                            source: days,
                            x: 'log_date',
                            y: 'out_of_compliance',
                        },
                        scale: {color: {type: 'quantize', scheme: 'Blues', domain: domain ?? [0, max]}},
                    },
                    [...plugins, [
                        Tooltip,
                        {
                            enabled: true,
                            text: (timestamp, value, dayjsDate) => {
                                const date = moment(dayjsDate.$d).utc().format('MM/DD/YYYY');

                                return tooltipUnit(value, date);
                            },
                            placement: 'auto',
                        }
                    ]]
                );

                isPainted.current = true; // Mark that the heatmap has painted
            }, 200)

            // Clean-up to avoid memory leaks
            return () => calendar.destroy();
        }
    }, []);

    return (
        <div id={`cal-heatmap-${year}`} ref={heatmapContainerRef}>
            <div className='mb-3 w-100 bg-[#EDEDED] py-1 pl-2'>
                <text className="font-bold text-[10px]">{year}</text>
            </div>
        </div>
    );
}
