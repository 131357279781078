import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Spinner, Chip,
} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import UnitDetailsTable from "./UnitDetailsTable";
import DocumentsMeasuredTable from "./DocumentsMeasuredTable";
import OutOfComplianceTimeline from "./OutOfComplianceTimeline";
import OutOfComplianceCalendar from "./OutOfComplianceCalendar";
import {previousReportIdAtom} from "../_atoms/lapseReportAtom";
import {useAtomValue} from "jotai";

export default function HighAlertUnitViewModal({isOpen, onOpenChange, unit, rangeDates, isShare}) {
    const [documentsMeasured, setDocumentsMeasured] = useState({
        isLoading: true,
        result: [],
    });
    const [timeline, setTimeline] = useState({
        isLoading: true,
        result: [],
    });
    const previousReportId = useAtomValue(previousReportIdAtom)

    const fetchDocumentsMeasured = async () => {
        setDocumentsMeasured({
            isLoading: true,
            result: [],
        });

        const {response} = await LapseReportApi.documentsMeasured({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            unitId: unit.unit_id,
            previousReportId,
            unitType: unit.unit_type === 'Truck' ? 'truck' : 'driver',
        });

        setDocumentsMeasured({
            isLoading: false,
            result: response,
        });
    }

    const fetchTimeline = async () => {
        setTimeline({
            isLoading: true,
            result: [],
        });

        const {response} = await LapseReportApi.outOfComplianceTimeline({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            unitId: unit.unit_id,
            previousReportId,
            unitType: unit.unit_type === 'Truck' ? 'truck' : 'driver',
        });

        setTimeline({
            isLoading: false,
            result: response,
        });
    }

    useEffect(() => {
        if (isOpen) {
            fetchDocumentsMeasured();
            fetchTimeline();
        }
    }, [isOpen]);

    return (<>
        <Modal
            className={'max-w-[65em] right-[-8em]'}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={false}
            isKeyboardDismissDisabled={true}
            classNames={{
                closeButton: "border-none bg-white mt-4 mr-3",
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="text-2xl font-bold text-blue-950 pt-3 mt-3 pb-0">{unit.unit_type} Details</ModalHeader>
                        <Divider className={'mb-3'}/>
                        <ModalBody>
                            <UnitDetailsTable unit={unit}></UnitDetailsTable>
                            <DocumentsMeasuredTable
                                className={'mt-3'}
                                isLoading={documentsMeasured.isLoading}
                                documents={documentsMeasured.result}
                                isShare={isShare}
                            ></DocumentsMeasuredTable>
                            <OutOfComplianceTimeline className={'mt-3'} rows={timeline.result}/>
                        </ModalBody>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>);
}
