import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Spinner,
} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {VscCloudDownload, VscLoading, VscShare} from "react-icons/vsc";
import {useAsyncList} from "react-stately";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import {getLocalTimeZone, parseDate} from "@internationalized/date";
import moment from "moment";
import {MdBrowserUpdated, MdDelete, MdSimCardDownload, MdUpdate} from "react-icons/md";
import {GoUpload} from "react-icons/go";
import {IoMdTrash} from "react-icons/io";
import {BiSolidTrash} from "react-icons/bi";
import {previousReportIdAtom, filtersAtom, previousReportAtom} from "../_atoms/lapseReportAtom";
import {useAtom} from "jotai";
import {UNIT_TYPES} from "../../../_constants/unit-type";

export default function PreviousReport({
       isOpen,
       onOpen,
       onOpenChange,
       rangeDates,
       setPreviousReport,
       setDefaultFilters,
       setRangeDates,
       setLapseReportRangeDates
 }) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [previousReportId, setPreviousReportId] = useAtom(previousReportIdAtom)
    const [previousReportItem, setPreviousReportItem] = useAtom(previousReportAtom)
    const [filters, setFilters] = useAtom(filtersAtom)

    const fetchReports = async () => {
        const {response} = await LapseReportApi.getPreviousReports();

        setItems(response);
        setIsLoading(false);
    };

    const loadReport = async (reportId, lapseReport) => {
        setPreviousReport(reportId);
        setPreviousReportId(reportId)
        setPreviousReportItem(lapseReport)
        setIsLoading(true);

        const {response} = await LapseReportApi.loadPreviousReport(reportId);

        setFilters({
            dic_filters: response.dic_filters,
            doc_filters: response.doc_filters,
            uic_filters: response.uic_filters,
            uoc_filters: response.uoc_filters,
            occ_document_filters: response.occ_document_filters,
            occ_unit_filters: response.occ_unit_filters,
            high_alert_unit_filters: response.high_alert_unit_filters ?? {
                search_text: null,
                units: UNIT_TYPES,
                percentage: 50,
                status: [1, 0],
                compliant: [1, 0],
            },
        })

        setDefaultFilters({
            dic_filters: response.dic_filters,
            doc_filters: response.doc_filters,
            uic_filters: response.uic_filters,
            uoc_filters: response.uoc_filters,
            occ_document_filters: response.occ_document_filters,
            occ_unit_filters: response.occ_unit_filters,
        });

        const rangeDates = {
            start: parseDate(moment(response.start_date).format('YYYY-MM-DD')),
            end: parseDate(moment(response.end_date).format('YYYY-MM-DD')),
        };

        setLapseReportRangeDates({
            start: rangeDates.start.toDate(getLocalTimeZone()),
            end: rangeDates.end.toDate(getLocalTimeZone()),
        });
        setRangeDates(rangeDates);
        setIsLoading(false);
        onOpenChange(false);
    };

    const deleteReportConfirmation = (id) => {
        const previousReport = items.find(item => item.id === id);

        swal({
            title: "Are you sure?",
            text: `You are about to delete Previous Lapse Report for "${previousReport.lapse_report}"`,
            type: "error",
            showCancelButton: true,
            cancelButtonClass: 'btn-default btn-md waves-effect',
            confirmButtonClass: 'btn-danger btn-md waves-effect waves-light',
            confirmButtonText: 'Delete!',
        },  async (confirmedDeleted) => {
            if (!confirmedDeleted) {
                return;
            }

            setIsLoading(true);
            setItems([]);

            await LapseReportApi.deletePreviousReport(id);
            await fetchReports();

            toastr.success("Previous Lapse Report has been deleted", {timeOut: 1000})

            return true;
        })
    }

    useEffect(() => {
        if (isOpen) {
            fetchReports();
        }
    }, [isOpen]);

    return (
        <>
            <Modal
                className={'max-w-[65em] right-[-8em]'}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                isDismissable={false}
                isKeyboardDismissDisabled={true}
                classNames={{
                    closeButton: "border-none bg-white mt-4 mr-3",
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-2xl font-bold text-blue-950 pt-3 mt-3 pb-0">Previous
                                Reports</ModalHeader>
                            <Divider className={'mb-3'}/>
                            <ModalBody>
                                <Table isHeaderSticky classNames={{
                                    th: 'bg-default-100 py-[15px]',
                                    base: "max-h-[520px] overflow-scroll",
                                }}>
                                    <TableHeader>
                                        <TableColumn width={'200px'}>LAPSE REPORT</TableColumn>
                                        <TableColumn>REMARKS</TableColumn>
                                        <TableColumn width={'150px'}>DATE GENERATED</TableColumn>
                                        <TableColumn>GENERATED BY</TableColumn>
                                        <TableColumn>LOAD REPORT</TableColumn>
                                    </TableHeader>
                                    <TableBody
                                        isLoading={isLoading}
                                        loadingContent={<Spinner label={'Loading...'}></Spinner>}
                                    >
                                        {items && items.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={'leading-snug'}>{item.lapse_report}</TableCell>
                                                <TableCell className={'leading-snug'}>{item.remarks}</TableCell>
                                                <TableCell
                                                    className={'leading-snug'}>{moment(item.report_date).format('MM/DD/YY HH:mm:ss')}</TableCell>
                                                <TableCell className={'leading-snug'}>{item.user.name}</TableCell>
                                                <TableCell className='grid grid-flow-col gap-4 justify-center'>
                                                    <Tooltip content="Load Report" className={`text-default-900 py-3`}>
                                                        <span
                                                            className={`text-[1.3em] text-default-500  active:opacity-50`}
                                                            onClick={() => loadReport(item.id, item)}>
                                                            <MdBrowserUpdated className={'cursor-pointer'}/>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip content="Delete Report" className={`text-white bg-red-500 py-3`}>
                                                        <span
                                                            className={`text-[1.3em] text-danger-500  active:opacity-50`}
                                                            onClick={() => deleteReportConfirmation(item.id)}>
                                                            <BiSolidTrash className={'cursor-pointer'}/>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    {(items.length === 0 && !isLoading) && (
                                        <TableBody emptyContent={"No reports to display."}>{[]}</TableBody>)}
                                </Table>
                            </ModalBody>
                            <Divider className={'mb-3'}/>
                            <ModalFooter className={'justify-between'}>
                                <Button color="default"
                                        className={'float-left border border-gray text-lg h-[35px] drop-shadow-none'}
                                        variant="light"
                                        onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
