import DocumentsOutOfComplianceChart from "./charts/DocumentsOutOfComplianceChart";
import DocumentsInComplianceChart from "./charts/DocumentsInComplianceChart";
import {DateRangePicker} from "@nextui-org/react";
import LapseReportHeading from "./LapseReportHeading";
import {useEffect, useState} from "react";
import {
    today,
    getLocalTimeZone,
    startOfMonth,
    endOfMonth,
    parseDate,
    parseAbsoluteToLocal
} from "@internationalized/date"
import LapseReportWidget from "./widgets/LapseReportWidget";
import UnitsOutOfComplianceChart from "./charts/UnitsOutOfComplianceChart";
import UnitsInComplianceChart from "./charts/UnitsInComplianceChart";
import DaysOutOfComplianceChart from "./charts/DaysOutOfComplianceChart";
import HighAlertDocumentsChart from "./charts/HighAlertDocumentsChart";
import LapseReportApi from "../../http/apis/lapse-report-api";
import OutOfComplianceCalendarChart from "./charts/OutOfComplianceCalendarChart"
import PageUtils from "./utils/page-utils";
import {isEmpty} from "lodash";
import useShareReport from "./_hooks/useShareReport";
import HighAlertUnitTable from "./high-alert-unit/HighAlertUnitTable";
import {previousReportIdAtom, previousReportAtom} from './_atoms/lapseReportAtom'
import {useAtom, useSetAtom} from "jotai";
import PreviousLapseReportAlert from "./_shared/PreviousLapseReportAlert";

export default function LapseReportPage({}) {
    const [rangeDates, setRangeDates] = useState({
        start: today(getLocalTimeZone()).subtract({days: 30}),
        end: today(getLocalTimeZone()),
    });
    const [lapseReportRangeDates, setLapseReportRangeDates] = useState({
        start: today(getLocalTimeZone()).subtract({days: 30}).toDate(),
        end: today(getLocalTimeZone()).toDate(),
    });
    const [isRunning, setIsLoading] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const [summary, setSummary] = useState(null);
    const [data, setData] = useState([]);
    const [isShare, setIsShare] = useState(true);

    // KPIs
    const [daysKpi, setDaysKpi] = useState(null);
    const [unitsKpi, setUnitsKpi] = useState(null);
    const [documentsKpi, setDocumentsKpi] = useState(null);

    // Charts
    const [unitChart, setUnitChart] = useState(null);
    const [dayChart, setDayChart] = useState(null);
    const [highAlertDocuments, setHighAlertDocuments] = useState(null);

    // Previous Reports
    const [previousReportId, setPreviousReportId] = useAtom(previousReportIdAtom)
    const [previousReport, setPreviousReport] = useState(null);
    const setPreviousReportItem = useSetAtom(previousReportAtom);
    const [defaultFilters, setDefaultFilters] = useState({
        dic_filters: null,
        doc_filters: null,
        uic_filters: null,
        uoc_filters: null,
        occ_document_filters: null,
        occ_unit_filters: null,
    });

    // TODO: use global state on this soon
    const {
        filters,
        setDocumentInCompliance,
        setDocumentOutOfCompliance,
        setUnitInCompliance,
        setUnitOutOfCompliance,
        setCalendarDocsOutOfCompliance,
        setCalendarUnitsOutOfCompliance,
    } = useShareReport();

    const filterReport = async () => {
        setIsLoading(true);

        const {response: {summary, details}} = await LapseReportApi.run({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
        });

        setSummary(summary);
        setData(details);

        setIsLoading(false);
    }

    const generate = async () => {
        setPreviousReportId(null)
        setPreviousReportItem(null)
        setLapseReportRangeDates({
            start: rangeDates.start.toDate(getLocalTimeZone()),
            end: rangeDates.end.toDate(getLocalTimeZone()),
        });

        setIsGenerating(true);

        await LapseReportApi.regenerate({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
        });

        await fetchKips();

        setIsGenerating(false);
    };

    useEffect(() => {
        if (previousReport) fetchKips();
    }, [previousReport]);

    const fetchKips = async () => {
        setPreviousReport(null);
        setIsLoading(true);

        const {response: {days_kpi, documents_kpi, units_kpi}} = await LapseReportApi.kpis({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
            previousReportId: previousReport,
        });

        fetchCharts();

        setDaysKpi(days_kpi);
        setDocumentsKpi(documents_kpi);
        setUnitsKpi(units_kpi);

        setIsLoading(false);
    };

    const fetchUnitCharts = async () => {
        const {response: units} = await LapseReportApi.unitsInAndOutOfCompliance({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
        });

        setUnitChart(units);
    };

    const fetchDayCharts = async () => {
        const {response} = await LapseReportApi.daysInAndOutOfCompliance({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
            previousReportId: previousReport,
        });

        setDayChart(response);
    };

    const fetchHighAlertDocumentCharts = async () => {
        const {response} = await LapseReportApi.highAlertDocuments({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
            previousReportId: previousReport,
        });

        setHighAlertDocuments(response);
    };

    const fetchCharts = async () => {
        fetchUnitCharts();
        fetchDayCharts();
        fetchHighAlertDocumentCharts();
    };

    const fetchShareByHash = async () => {
        const hash = PageUtils.getShareHash();

        if (!hash) {
            return;
        }

        const {response} = await LapseReportApi.findByHash({hash});
        if (!isEmpty(response)) {
            setRangeDates({
                start: parseDate(response.start_date),
                end: parseDate(response.end_date),
            });
        }
    };

    useEffect(() => {
        fetchKips();
        fetchShareByHash();

        setIsShare(!!PageUtils.getShareHash());
    }, []);

    return (
        <>
            <div className="tailwind">
                <div className="flex flex-col sm:flex-row mb-20">
                    <LapseReportHeading
                        rangeDates={rangeDates}
                        setRangeDates={setRangeDates}
                        setLapseReportRangeDates={setLapseReportRangeDates}
                        filterReport={fetchKips}
                        isRunning={isRunning}
                        isGenerating={isGenerating}
                        generate={generate}
                        isShare={isShare}
                        filters={filters}
                        setPreviousReport={setPreviousReport}
                        setDefaultFilters={setDefaultFilters}
                    />
                </div>

                <PreviousLapseReportAlert/>

                <div className="w-full flex flex-row mb-10">
                    <LapseReportWidget
                        isLoading={isRunning || isGenerating}
                        complianceScore={summary?.compliance ?? 0}
                        documentPercentInCompliance={documentsKpi?.in_percentage ?? 0}
                        documentsOutOfCompliance={documentsKpi?.out_of_compliance ?? 0}

                        unitsPercentInCompliance={unitsKpi?.in_percentage ?? 0}
                        unitsOutOfCompliance={unitsKpi?.out_of_compliance ?? 0}

                        daysPercentInCompliance={daysKpi?.in_percentage ?? 0}
                        daysOutOfCompliance={daysKpi?.out_of_compliance ?? 0}
                    />
                </div>
                <div className="w-full flex flex-col md:flex-row  mb-10 gap-6">
                    <div className="sm:w-100 md:w-1/2 flex flex-col">
                        <DocumentsOutOfComplianceChart
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isGenerating}
                        />
                    </div>
                    <div className="sm:w-100 md:w-1/2 flex flex-col">
                        <DocumentsInComplianceChart
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isGenerating}
                            setDocumentInCompliance={setDocumentInCompliance}
                            previousReport={previousReport}
                            defaultFilter={defaultFilters.dic_filters}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row mb-10 gap-6">
                    <div className="sm:w-100 md:w-1/2 flex flex-col">
                        <UnitsOutOfComplianceChart
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isGenerating}
                            setFilter={setUnitOutOfCompliance}
                            previousReport={previousReport}
                            defaultFilter={defaultFilters.uoc_filters}
                        />
                    </div>
                    <div className="sm:w-100 md:w-1/2 flex flex-col">
                        <UnitsInComplianceChart
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isGenerating}
                            setFilter={setUnitInCompliance}
                            previousReport={previousReport}
                            defaultFilter={defaultFilters.uic_filters}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row mb-10 gap-6">
                    <div className="sm:w-100 md:w-1/3 flex flex-col">
                        <DaysOutOfComplianceChart
                            daysOutOfComplianceCount={`${daysKpi?.out_of_compliance} / ${daysKpi?.total}`}
                            daysOutOfCompliancePercentage={daysKpi?.out_percentage ?? 0}
                            data={dayChart ?? []}
                            isGenerating={isRunning || isGenerating}
                        />
                    </div>
                    <div className="sm:w-100 md:w-2/3 flex flex-col">
                        <HighAlertDocumentsChart data={highAlertDocuments} isGenerating={isRunning || isGenerating}/>
                    </div>
                </div>
                <div className="w-full flex flex-row mb-10">
                    <div className="w-full flex flex-col">
                        <OutOfComplianceCalendarChart
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isRunning || isGenerating}
                            setCalendarDocsOutOfCompliance={setCalendarDocsOutOfCompliance}
                            setCalendarUnitsOutOfCompliance={setCalendarUnitsOutOfCompliance}
                            previousReport={previousReport}
                            docFilter={defaultFilters.occ_document_filters}
                            unitFilter={defaultFilters.occ_unit_filters}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-row mb-10">
                    <div className="w-full flex flex-col">
                        <HighAlertUnitTable
                            rangeDates={lapseReportRangeDates}
                            isGenerating={isRunning || isGenerating}
                            isShare={isShare}
                        ></HighAlertUnitTable>
                    </div>
                </div>
            </div>
        </>
    );
}
