import {useState} from "react";

export default function useShareReport() {
    const [documentInCompliance, setDocumentInCompliance] = useState([]);
    const [documentOutOfCompliance, setDocumentOutOfCompliance] = useState([]);
    const [unitInCompliance, setUnitInCompliance] = useState([]);
    const [unitOutOfCompliance, setUnitOutOfCompliance] = useState([]);
    const [calendarDocsOutOfCompliance, setCalendarDocsOutOfCompliance] = useState([]);
    const [calendarUnitsOutOfCompliance, setCalendarUnitsOutOfCompliance] = useState([]);

    const setDocumentInComplianceFilter = (documentInCompliance) => {
        setDocumentInCompliance(documentInCompliance);
    };
    const setDocumentOutOfComplianceFilter = (documentOutOfCompliance) => {
        setDocumentOutOfCompliance(documentOutOfCompliance);
    };
    const setUnitInComplianceFilter = (unitInCompliance) => {
        setUnitInCompliance(unitInCompliance);
    };
    const setUnitOutOfComplianceFilter = (unitOutOfCompliance) => {
        setUnitOutOfCompliance(unitOutOfCompliance);
    };
    const calendarDocsOutOfComplianceFilter = (calendarDocsOutOfCompliance) => {
        setCalendarDocsOutOfCompliance(calendarDocsOutOfCompliance);
    };
    const calendarUnitsOutOfComplianceFilter = (calendarUnitsOutOfCompliance) => {
        setCalendarUnitsOutOfCompliance(calendarUnitsOutOfCompliance);
    };

    return {
        filters: {
            documentInCompliance,
            documentOutOfCompliance,
            unitInCompliance,
            unitOutOfCompliance,
            calendarDocsOutOfCompliance,
            calendarUnitsOutOfCompliance,
        },
        setDocumentInCompliance,
        setDocumentOutOfCompliance,
        setUnitInCompliance,
        setUnitOutOfCompliance,
        setCalendarDocsOutOfCompliance,
        setCalendarUnitsOutOfCompliance,
    };
}
