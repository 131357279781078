import {
    ResponsiveContainer,
    PieChart,
    Tooltip,
    Legend,
    Pie,
    Cell,
} from 'recharts';
import React from "react";
import {Skeleton} from "@nextui-org/skeleton";

const CustomTooltip = ({active, payload, label}) => {
    const item = payload[0]?.payload?.payload ?? null;

    return (
        <div
            className="custom-tooltip text-tiny mb-2"
            style={{
                backgroundColor: "#fff",
                padding: "10px",
                border: "1px solid #ccc",
            }}
        >
            <p className={'mb-2'}>{item?.name}: {item?.value}%</p>
        </div>
    );
}

export default function DaysOutOfComplianceChart(
    {
        daysOutOfComplianceCount,
        daysOutOfCompliancePercentage,
        data,
        isGenerating,
    }
) {
    return (
        <>
            <div className="mb-3">
                <p className="mb-4 text-3xl font-bold text-blue-950">Days out of Compliance</p>
            </div>
            <div className={"flex flex-col"}>
                <div
                    className="w-full border-solid border-1 border-gray rounded-lg px-6 py-6">
                    <span>Number of days out of compliance:</span>
                    <div className="flex flex-row">
                        {(isGenerating) && (
                            <>
                                <Skeleton isLoaded={!(isGenerating)}
                                          class={'h-[20px] w-[95px] mr-[60px] bg-gray-skeleton rounded-lg'}></Skeleton>
                                <Skeleton isLoaded={!(isGenerating)}
                                          class={'h-[20px] w-[95px] bg-gray-skeleton rounded-lg'}></Skeleton>
                            </>
                        )}
                        {!(isGenerating) && (
                            <>
                              <span
                                  className="font-semibold text-2xl w-56">{daysOutOfComplianceCount}</span><span
                                className="font-semibold text-2xl">{daysOutOfCompliancePercentage}%</span>
                            </>
                        )}
                    </div>
                </div>
                { (isGenerating) && (
                    <Skeleton isLoaded={false}
                              className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pb-5 h-[40rem] mt-3">
                        <div style={{width: '100%'}}>
                            <ResponsiveContainer width="100%" height="100%">
                            </ResponsiveContainer>
                        </div>
                    </Skeleton>
                )}
                { !(isGenerating) && (
                    <div className="flex flex-row border-solid border-1 border-gray rounded-lg pb-5 h-[40rem] mt-3">
                        <div style={{width: '100%'}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        <Cell
                                            key={`cell-0`}
                                            fill="#582AC6"
                                        />
                                        <Cell
                                            key={`cell-1`}
                                            fill="#57BADF"
                                        />
                                    </Pie>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
