import React, {PureComponent, useState, useEffect, useMemo} from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Legend,
    Bar,
    Rectangle,
    Brush,
    ReferenceLine,
} from 'recharts';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tab,
    Tabs,
    CheckboxGroup
} from "@nextui-org/react";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import moment from "moment";
import UnitTypeFilter from "../_shared/UnitTypeFilter";
import {getLocalTimeZone} from "@internationalized/date";
import {sumBy, map} from "lodash";
import useUnitComplianceChart from "../_hooks/useUnitComplianceChart";
import {Skeleton} from "@nextui-org/skeleton";

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length > 0) {
        const {
            label = null,
            log_date,
            driver_out_of_compliance,
            truck_out_of_compliance,
            out_of_compliance
        } = payload[0]?.payload ?? null;

        return (
            <div
                className="custom-tooltip text-tiny mb-2"
                style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                }}
            >
                <p className="label font-semibold mb-2">{label}</p>
                <p className={'mb-2'}>{`Date: ${label ?? log_date}`}</p>
                <p className={'mb-2'}>{`Driver: ${driver_out_of_compliance}`}</p>
                <p className={'mb-2'}>{`Truck: ${truck_out_of_compliance}`}</p>
                <p>{`Total: ${out_of_compliance}`}</p>
            </div>
        );
    }

    return null;
};

export default function UnitsOutOfComplianceChart({rangeDates, isGenerating, setFilter, previousReport, defaultFilter}) {
    const [selected, setSelected] = useState("total");
    const {data, units, onUnitChange, isLoading} = useUnitComplianceChart({
        rangeDates,
        isGenerating,
        setFilter,
        previousReport,
        key: 'uoc_response',
        defaultFilter,
    });

    return (<>
        <div className="mb-3">
            <p className="mb-4 text-3xl font-bold text-blue-950">Units out of Compliance</p>
        </div>
        <div className={"flex flex-row items-end mb-2 gap-6"}>
            <div
                className="w-4/5 border-solid border-1 border-gray rounded-lg px-6 py-6">
                <span>Total number of units out of compliance:</span>
                <div className="flex flex-row">
                    {(isLoading || isGenerating) && (
                        <>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'animate-pulse h-[20px] w-[95px] mr-[60px] bg-skeleton rounded-lg'}></Skeleton>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'animate-pulse h-[20px] w-[95px] bg-skeleton rounded-lg'}></Skeleton>
                        </>
                    )}
                    {!(isLoading || isGenerating) && (
                        <>
                              <span
                                  className="font-semibold text-2xl w-56">{data.out_of_compliance} / {data.total}</span><span
                            className="font-semibold text-2xl">{data.out_percentage}%</span>
                        </>
                    )}
                </div>
            </div>
            <div className="w-1/4">
                <Tabs
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                    size={"lg"}
                    color={"primary"}
                    classNames={{
                        base: "w-100",
                        tabList: "w-100"
                    }}
                >
                    <Tab key="total" title="Total" className={'text-lg'}></Tab>
                    <Tab key="segmented" title="Segmented" className={'text-lg'}></Tab>
                </Tabs>
                <UnitTypeFilter onChange={onUnitChange} value={units}></UnitTypeFilter>
            </div>
        </div>
        {(isLoading || isGenerating) && (
            <Skeleton isLoaded={false}
                      className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '100%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                    </ResponsiveContainer>
                </div>
            </Skeleton>
        )}
        {!(isLoading || isGenerating) && (
            <div className="flex flex-row border-solid border-1 border-gray rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '97%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={data.chart} barGap={2}>
                            <CartesianGrid stroke="#e6e6e6" vertical={false}/>
                            <XAxis
                                angle={-30}
                                dataKey="log_date"
                                tick={{fontSize: 14}}
                                tickMargin={10}
                                tickSize={10}
                                height={100}
                            />
                            <YAxis/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>
                            <ReferenceLine y={0} stroke="#000"/>
                            {selected === 'segmented' && (
                                <>
                                    <Bar dataKey="driver_out_of_compliance" name="Driver" stackId="total"
                                         fill="#582AC6"/>
                                    <Bar dataKey="truck_out_of_compliance" name="Truck" stackId="total" fill="#57BADF"/>
                                </>
                            )}
                            {selected === 'total' && (
                                <>
                                    <Bar dataKey="out_of_compliance" name="Total" stackId="total" fill="#57BADF"/>
                                </>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )}
    </>);
}
