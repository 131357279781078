import React from "react";
import { Chart } from "react-google-charts";
import moment from "moment";

const columns = [
    { type: "string", id: "Document" },
    { type: "string", id: "Compliance" },
    { type: "string", role: "style" },
    { type: "string", role: "tooltip" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
];

export default function OutOfComplianceTimeline({rows}) {

    rows = rows.map((row) => {
        const [syear, smonth, sdate] = row[2].split('-').map(i => parseInt(i));
        const [eyear, emonth, edate] = row[3].split('-').map(i => parseInt(i));

        return [
            row[0],
            row[1],
            row[4],
            row[5],
            new Date(syear, smonth - 1, sdate),
            new Date(eyear, emonth - 1, edate),
        ];
    });

    const data = [columns, ...rows]

    return (
        <>
            <span className={'text-2xl text-bold'}>Out of Compliance Timeline</span>
            {
                rows.length > 0 && (
                    <Chart
                        chartType="Timeline"
                        data={data}
                        width="100%"
                        height="auto"
                        options={{
                            tooltip: {isHtml: true},
                            timeline: {
                                showBarLabels: false,
                            },
                        }}
                    />
                )
            }
            {
                rows.length === 0 && (
                    <div className={'text-center w-100'}>
                        <span className={'text-2xl italic'}>All documents are compliant</span>
                    </div>
                )
            }
        </>
    );
}
