import React, {useEffect, useState} from "react";
import {
    Button,
    Chip,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Slider,
    Switch,
    useDisclosure, CheckboxGroup, Checkbox
} from "@nextui-org/react";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import {getLocalTimeZone} from "@internationalized/date";
import {MdBrowserUpdated, MdPanoramaFishEye} from "react-icons/md";
import {EyeIcon} from "../../icons/EyeIcon";
import HighAlertUnitViewModal from "./HighAlertUnitViewModal";
import {Skeleton} from "@nextui-org/skeleton";
import SkeletonTable from "../_shared/SkeletonTable";
import UnitTypeFilter from "../_shared/UnitTypeFilter";
import {DRIVER_TYPE, UNIT_TYPES} from "../../../_constants/unit-type";
import {filtersAtom, previousReportIdAtom} from "../_atoms/lapseReportAtom";
import {useAtom, useAtomValue} from "jotai";

let debounceSearch = null;

export default function HighAlertUnitTable({rangeDates, isGenerating, isShare}) {
    const [highAlertUnits, setHighAlertUnits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [unit, setUnit] = useState(null);
    const [filters, setFilters] = useAtom(filtersAtom)
    const previousReportId = useAtomValue(previousReportIdAtom)

    const fetchHighAlertUnits = async (filters = {}) => {
        setIsLoading(true);

        const {response} = await LapseReportApi.highAlertUnits({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            previousReportId,
            filters,
        });

        setHighAlertUnits(response);

        setIsLoading(false);
    }

    const openViewModal = (unit) => {
        setUnit(unit);

        onOpenChange(true);
    }

    const onChangeFilters = (nFilter) => {
        const query = {...filters.high_alert_unit_filters, ...nFilter};
        setFilters({...filters, high_alert_unit_filters: query})

        if (debounceSearch) {
            clearTimeout(debounceSearch)
        }

        debounceSearch = setTimeout(() => {
            fetchHighAlertUnits(query)
        }, 1000)
    }

    const statusLabel = () => {
        if (filters.high_alert_unit_filters.status.length === 2) {
            return 'Any Status'
        }

        return filters.high_alert_unit_filters.status[0] === 1 ? 'Active Units' : 'Inactive Units'
    }

    const compliantLabel = () => {
        if (filters.high_alert_unit_filters.compliant.length === 2) {
            return 'Any Compliance'
        }

        return filters.high_alert_unit_filters.compliant[0] === 1 ? 'Compliant Units' : 'Out of compliance Units'
    }

    const unitLabel = () => {
        if (filters.high_alert_unit_filters.units.length === 2) {
            return 'Unit Type'
        }

        return filters.high_alert_unit_filters.units[0] === DRIVER_TYPE ? 'Drivers' : 'Trucks'
    }

    const isDisabled = (key, value) => {
        return (filters.high_alert_unit_filters[key].length === 1 && filters.high_alert_unit_filters[key][0] === value)
            || previousReportId !== null
    }

    useEffect(() => {
        setIsLoading(isGenerating)
        if (isGenerating === false) {
            fetchHighAlertUnits(filters.high_alert_unit_filters);
        }
    }, [isGenerating]);

    return (
        <>
            <div className="mb-3">
                <p className="mb-4 text-3xl font-bold text-blue-950">High Alert Units (Most out of Compliance)</p>
            </div>
            <div className={'flex mb-[20px] gap-3'}>
                <div className={'w-1/5'}>
                    <input
                        placeholder="Search here..."
                        className="form-control"
                        size="sm"
                        disabled={previousReportId !== null}
                        value={filters.high_alert_unit_filters.search_text}
                        onChange={(event) => onChangeFilters({search_text: event.target.value})}/>
                </div>
                <div>
                    <Popover placement="bottom" showArrow={true} size="sm">
                        <PopoverTrigger>
                            <Button
                                variant="bordered"
                                color="primary"
                                className="min-w-0 h-[35px]"
                                size="sm"
                            >
                                {unitLabel()}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className="px-1 py-2">
                                <CheckboxGroup
                                    label={<p>Select Unit Types</p>}
                                    onChange={value => onChangeFilters({units: value})}
                                    value={filters.high_alert_unit_filters.units}
                                    size={"lg"}
                                >
                                    <Checkbox value="driver" isDisabled={isDisabled('units', 'driver')}>Drivers</Checkbox>
                                    <Checkbox value="truck" isDisabled={isDisabled('units', 'truck')}>Trucks</Checkbox>
                                </CheckboxGroup>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <Popover placement="bottom" showArrow={true} size="sm">
                    <PopoverTrigger>
                        <Button
                            variant="bordered"
                            color="primary"
                            className="min-w-0 h-[35px]"
                            size="sm"
                        >
                            {"<"} {filters.high_alert_unit_filters.percentage}% Compliance
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <div className="px-1 py-2">
                            <Slider
                                classNames={{
                                    base: 'max-w-md w-[50em]',
                                    label: 'font-normal',
                                    value: 'pt-0'
                                }}
                                size="sm"
                                step={10}
                                color="primary"
                                label="< Compliance %"
                                showSteps={true}
                                maxValue={100}
                                minValue={0}
                                onChange={value => onChangeFilters({percentage: value})}
                                value={filters.high_alert_unit_filters.percentage}
                                isDisabled={previousReportId !== null}
                            />
                        </div>
                    </PopoverContent>
                </Popover>
                <Popover placement="bottom" showArrow={true} size="sm">
                    <PopoverTrigger>
                        <Button
                            variant="bordered"
                            color="primary"
                            className="min-w-0 h-[35px]"
                            size="sm"
                        >
                            {statusLabel()}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <div className="px-1 py-2">
                            <CheckboxGroup
                                label={<p>Select Status</p>}
                                onChange={value => onChangeFilters({status: value})}
                                size={"lg"}
                                value={filters.high_alert_unit_filters.status}
                            >
                                <Checkbox value={1} isDisabled={isDisabled('status', 1)}>Active</Checkbox>
                                <Checkbox value={0} isDisabled={isDisabled('status', 0)}>Inactive</Checkbox>
                            </CheckboxGroup>
                        </div>
                    </PopoverContent>
                </Popover>
                <Popover placement="bottom" showArrow={true} size="sm">
                    <PopoverTrigger>
                        <Button
                            variant="bordered"
                            color="primary"
                            className="min-w-0 h-[35px]"
                            size="sm"
                        >
                            {compliantLabel()}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <div className="px-1 py-2">
                            <CheckboxGroup
                                label={<p>Select Compliance</p>}
                                onChange={value => onChangeFilters({compliant: value})}
                                size={"lg"}
                                value={filters.high_alert_unit_filters.compliant}
                            >
                                <Checkbox value={1} isDisabled={isDisabled('compliant', 1)}>Compliant</Checkbox>
                                <Checkbox value={0} isDisabled={isDisabled('compliant', 0)}>Out of Compliance</Checkbox>
                            </CheckboxGroup>
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
            {isLoading && <SkeletonTable rows={5}></SkeletonTable>}
            {isLoading === false && (
                <Table aria-labelledby="high-alert-unit" isHeaderSticky classNames={{
                    th: 'bg-default-100 py-[15px]',
                    base: "max-h-[520px] overflow-scroll",
                    td: 'py-[1rem]',
                    wrapper: 'px-0'
                }}>
                    <TableHeader>
                        <TableColumn>Unit ID</TableColumn>
                        <TableColumn>Name</TableColumn>
                        <TableColumn>Unit Type</TableColumn>
                        <TableColumn>Days out of Compliance</TableColumn>
                        <TableColumn>% in Compliance</TableColumn>
                        <TableColumn>Current Status</TableColumn>
                        <TableColumn>Current Compliance</TableColumn>
                        <TableColumn className={'text-center'}>Actions</TableColumn>
                    </TableHeader>
                    <TableBody
                        isLoading={isLoading}
                        loadingContent={<Spinner label={'Loading...'}></Spinner>}
                    >
                        {highAlertUnits && highAlertUnits.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.display_id}</TableCell>
                                <TableCell>{item.unit_type === 'Truck' ? item.vin : `${item.first_name} ${item.last_name}`}</TableCell>
                                <TableCell>
                                    <div className="flex flex-col">
                                        <p className="font-semibold capitalize">{item.unit_type}</p>
                                        <p className="mt-2 capitalize text-default-400">
                                            {item.company_name}
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell>{item.out_of_compliance_days}</TableCell>
                                <TableCell>{item.percentage_in_compliance}%</TableCell>
                                <TableCell>
                                    {
                                        item.latest_is_active === 0 && (
                                            <Chip
                                                className="capitalize h-[26px]"
                                                color="danger"
                                                size="md"
                                                variant="flat"
                                            >
                                                <p>Inactive</p>
                                            </Chip>
                                        )
                                    }
                                    {
                                        item.latest_is_active === 1 && (
                                            <Chip
                                                className="capitalize h-[26px] w-[30px]"
                                                color="success"
                                                size="md"
                                                variant="flat"
                                            >
                                                <p>Active</p>
                                            </Chip>
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        item.latest_is_compliant === 0 && (
                                            <div className="flex items-center">
                                                <div
                                                    className={`h-3 w-3 mr-3 rounded-full bg-red-500`}
                                                ></div>
                                                <p
                                                    className={`text-red-500`}
                                                >
                                                    Out of Compliance
                                                </p>
                                            </div>
                                        )
                                    }
                                    {
                                        item.latest_is_compliant === 1 && (
                                            <div className="flex items-center">
                                                <div
                                                    className={`h-3 w-3 mr-3 rounded-full bg-green-500`}
                                                ></div>
                                                <p
                                                    className={`text-green-500`}
                                                >
                                                    Compliant
                                                </p>
                                            </div>
                                        )
                                    }
                                </TableCell>
                                <TableCell className={'text-center '}>
                                    <div className="relative flex ml-2">
                                        <Tooltip content="Details" className="text-default-900 py-3">
                                      <span
                                          className="text-[1.3em] text-default-400 cursor-pointer active:opacity-50 mx-auto"
                                          onClick={() => openViewModal(item)}>
                                        <EyeIcon/>
                                      </span>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {(highAlertUnits.length === 0 && !isLoading) && (
                        <TableBody emptyContent={"No units to display."}>{[]}</TableBody>)}
                </Table>
            )}
            <HighAlertUnitViewModal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                unit={unit}
                rangeDates={rangeDates}
                isShare={isShare}
            ></HighAlertUnitViewModal>
        </>
    );
}
