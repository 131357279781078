import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Send, ArrowRight, CheckCircle } from "lucide-react";

const ShortLanding = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    fleetSize: "",
    challenge: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
        duration: 0.5
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 }
    }
  };

  const formVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 80, delay: 0.3 }
    }
  };

  const bulletVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1,
      transition: { type: "spring", stiffness: 100 }
    },
    hover: { 
      scale: 1.05,
      transition: { type: "spring", stiffness: 300 }
    }
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.03,
      boxShadow: "0 10px 15px -3px rgba(59, 130, 246, 0.3)",
      transition: { type: "spring", stiffness: 400 }
    },
    tap: { scale: 0.97 }
  };

  const backgroundBlobVariants = {
    initial: { scale: 1, rotate: 0 },
    animate: { 
      scale: [1, 1.05, 1],
      rotate: [0, 5, 0],
      transition: { 
        repeat: Infinity, 
        repeatType: "reverse", 
        duration: 20,
        ease: "easeInOut"
      }
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-digits
    const phoneNumber = value.replace(/\D/g, "");
    
    // Format the number as (XXX) XXX-XXXX
    if (phoneNumber.length >= 10) {
      return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`;
    } else if (phoneNumber.length > 6) {
      return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length > 3) {
      return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`;
    } else if (phoneNumber.length > 0) {
      return `(${phoneNumber}`;
    }
    return phoneNumber;
  };

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      const formattedPhone = formatPhoneNumber(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: formattedPhone,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate phone number
    const phoneDigits = formData.phone.replace(/\D/g, "");
    if (phoneDigits.length !== 10) {
      alert("Please enter a valid 10-digit phone number");
      return;
    }

    // Validate email ends with .com
    if (!formData.email.toLowerCase().endsWith(".com")) {
      alert("Please enter a valid email address ending with .com");
      return;
    }
    
    try {
      const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service_id: "service_bd8xqoi",
          template_id: "template_gdv4gzz",
          user_id: "moxGesNsHlG4mbFyg",
          template_params: {
            from_name: `${formData.firstName} ${formData.lastName}`,
            from_email: formData.email,
            company: formData.company,
            phone: formData.phone,
            fleet_size: formData.fleetSize,
            challenge: formData.challenge,
            message: JSON.stringify(formData, null, 2)
          }
        })
      });
      
      if (response.ok) {
        setFormSubmitted(true);
        setTimeout(() => {
          window.location.href = "/thankyou";
        }, 1500);
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit form. Please try again.");
    }
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white overflow-hidden">
      <div className="relative">
        {/* Animated background decoration */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <motion.div 
            className="absolute -top-1/2 -right-1/2 w-full h-full bg-blue-200 rounded-full blur-3xl opacity-10"
            variants={backgroundBlobVariants}
            initial="initial"
            animate="animate"
          />
          <motion.div 
            className="absolute -bottom-1/2 -left-1/2 w-full h-full bg-cyan-200 rounded-full blur-3xl opacity-10"
            variants={backgroundBlobVariants}
            initial="initial"
            animate="animate"
            style={{ animationDelay: "-5s" }}
          />
        </div>

        {/* Main content with higher z-index */}
        <div className="container mx-auto px-4 py-8 md:py-16 relative z-10">
          <motion.div 
            className="flex flex-col md:flex-row md:items-center md:justify-between md:gap-12"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {/* Logo and Headline - Left side on desktop */}
            <motion.div 
              className="text-center md:text-left mb-8 md:mb-0 md:w-1/2"
              variants={containerVariants}
            >
              <motion.div 
                className="w-48 mx-auto md:mx-0 mb-6"
                variants={itemVariants}
              >
                <motion.img
                  src={`${window.assetUrl}image/LogoOptimusBlue.png`}
                  alt="Optimus Logo"
                  width={192}
                  height={48}
                  className="object-contain w-full h-auto"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                />
              </motion.div>
              
              <motion.div 
                className="inline-flex items-center gap-2 bg-blue-100 text-blue-600 px-4 py-2 rounded-full mb-4 text-sm"
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
              >
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
                </span>
                #1 Rated DOT Compliance Solution
              </motion.div>

              <motion.h1 
                className="text-3xl md:text-5xl font-bold text-blue-900 mb-4 leading-tight"
                variants={itemVariants}
              >
                Transform Your <motion.span 
                  className="text-blue-500"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ 
                    opacity: 1, 
                    scale: 1,
                    transition: { 
                      delay: 0.5,
                      duration: 0.5
                    }
                  }}
                >Fleet Compliance</motion.span>
              </motion.h1>
              
              <motion.p 
                className="text-blue-700 text-sm md:text-lg max-w-2xl mx-auto md:mx-0 mb-6"
                variants={itemVariants}
              >
                Automate your DOT compliance, eliminate paperwork, and stay ahead of deadlines.
              </motion.p>
              
              {/* Benefits bullet points - visible on all devices */}
              <motion.div 
                className="mt-10 text-left"
                variants={containerVariants}
              >
                <ul className="space-y-3">
                  <motion.li 
                    className="flex items-start"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <motion.div 
                      className="flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mt-1"
                      variants={bulletVariants}
                    >
                      <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </motion.div>
                    <span className="ml-3 text-blue-800 font-medium">AI-Powered Document Management</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-start"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <motion.div 
                      className="flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mt-1"
                      variants={bulletVariants}
                    >
                      <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </motion.div>
                    <span className="ml-3 text-blue-800 font-medium">Centralized Compliance Hub</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-start"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <motion.div 
                      className="flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mt-1"
                      variants={bulletVariants}
                    >
                      <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </motion.div>
                    <span className="ml-3 text-blue-800 font-medium">Real-Time Alerts</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-start"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <motion.div 
                      className="flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center mt-1"
                      variants={bulletVariants}
                    >
                      <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </motion.div>
                    <span className="ml-3 text-blue-800 font-medium">Turn Compliance into a Business Asset</span>
                  </motion.li>
                </ul>
              </motion.div>
            </motion.div>

            {/* Contact Form Card - Right side on desktop */}
            <motion.div 
              className="w-full md:w-1/2 bg-white rounded-2xl shadow-xl p-6 md:p-8 border border-gray-100 relative z-20"
              variants={formVariants}
              initial="hidden"
              animate="visible"
              whileHover={{ boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" }}
              transition={{ duration: 0.3 }}
            >
              <AnimatePresence>
                {formSubmitted ? (
                  <motion.div 
                    className="flex flex-col items-center justify-center h-full py-12"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ type: "spring", stiffness: 200, delay: 0.2 }}
                    >
                      <CheckCircle size={80} className="text-green-500 mb-4" />
                    </motion.div>
                    <motion.h3 
                      className="text-xl font-bold text-blue-900 mb-2"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      Thank You!
                    </motion.h3>
                    <motion.p 
                      className="text-blue-700 text-center"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      Your submission has been received. We'll be in touch shortly.
                    </motion.p>
                  </motion.div>
                ) : (
                  <>
                    <motion.h2 
                      className="text-xl font-bold text-blue-900 mb-4 text-center"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      Get a Free Compliance Review
                    </motion.h2>

                    <form onSubmit={handleSubmit} className="space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <motion.div
                          initial={{ opacity: 0, x: -10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: 0.5 }}
                        >
                          <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                    focus:border-blue-500 focus:bg-white outline-none transition-all"
                            required
                          />
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, x: 10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: 0.5 }}
                        >
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                    focus:border-blue-500 focus:bg-white outline-none transition-all"
                            required
                          />
                        </motion.div>
                      </div>

                      <div className="grid grid-cols-1 gap-4">
                        <motion.input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Work Email"
                          className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                  focus:border-blue-500 focus:bg-white outline-none transition-all"
                          required
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.6 }}
                        />
                        <motion.input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder="(XXX) XXX-XXXX"
                          pattern="\(\d{3}\)\s\d{3}-\d{4}"
                          className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                  focus:border-blue-500 focus:bg-white outline-none transition-all"
                          required
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.7 }}
                        />
                        <motion.input
                          type="text"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          placeholder="Company Name"
                          className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                  focus:border-blue-500 focus:bg-white outline-none transition-all"
                          required
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.8 }}
                        />
                        <motion.select
                          name="fleetSize"
                          value={formData.fleetSize}
                          onChange={handleChange}
                          className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                  focus:border-blue-500 focus:bg-white outline-none transition-all"
                          required
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.9 }}
                        >
                          <option value="">Fleet Size</option>
                          <option value="1-10">1-10 vehicles</option>
                          <option value="11-50">11-50 vehicles</option>
                          <option value="51-200">51-200 vehicles</option>
                          <option value="200+">200+ vehicles</option>
                        </motion.select>
                        <motion.select
                          name="challenge"
                          value={formData.challenge}
                          onChange={handleChange}
                          className="w-full px-4 py-3 rounded-lg bg-blue-50 border-2 border-transparent 
                                  focus:border-blue-500 focus:bg-white outline-none transition-all"
                          required
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 1 }}
                        >
                          <option value="">Primary Challenge</option>
                          <option value="document-management">Document Management</option>
                          <option value="expiring-alerts">Expiring Alerts</option>
                          <option value="integration">Integration</option>
                          <option value="audit">Audit Preparation</option>
                          <option value="other">Other</option>
                        </motion.select>
                      </div>

                      <motion.button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-4 rounded-lg
                                transition-all duration-200 flex items-center justify-center gap-2 mt-6 border-none"
                        variants={buttonVariants}
                        initial="initial"
                        whileHover="hover"
                        whileTap="tap"
                      >
                        Get My Free Consultation
                        <motion.div
                          animate={{ x: [0, 5, 0] }}
                          transition={{ repeat: Infinity, duration: 1.5 }}
                        >
                          <ArrowRight className="w-4 h-4" />
                        </motion.div>
                      </motion.button>
                    </form>

                    <motion.p 
                      className="text-xs text-blue-700 text-center mt-4"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1.1 }}
                    >
                      By submitting, you agree to our{" "}
                      <a href="#" className="text-blue-600 hover:underline">Terms</a>
                      {" "}and{" "}
                      <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a>
                    </motion.p>
                  </>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ShortLanding; 