import React, {PureComponent} from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Legend,
    Bar,
    Rectangle,
    Brush,
    ReferenceLine,
} from 'recharts';
import {Button, Checkbox, CheckboxGroup, Popover, PopoverContent, PopoverTrigger, Tab, Tabs} from "@nextui-org/react";
import UnitTypeFilter from "../_shared/UnitTypeFilter";
import useUnitComplianceChart from "../_hooks/useUnitComplianceChart";
import {Skeleton} from "@nextui-org/skeleton";

const addPercentSymbol = (value) => `${value}%`;

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length > 0) {
        const {
            label = null,
            log_date,
            percentage_driver_in_compliance,
            percentage_truck_in_compliance,
            percentage_in_compliance
        } = payload[0]?.payload ?? null;

        return (
            <div
                className="custom-tooltip text-tiny mb-2"
                style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                }}
            >
                <p className="label font-semibold mb-2">{label}</p>
                <p className={'mb-2'}>{`Date: ${label ?? log_date}`}</p>
                <p className={'mb-2'}>{`Driver: ${percentage_driver_in_compliance}%`}</p>
                <p className={'mb-2'}>{`Truck: ${percentage_truck_in_compliance}%`}</p>
                <p>{`Total: ${percentage_in_compliance}%`}</p>
            </div>
        );
    }

    return null;
};

export default function UnitsInComplianceChart({rangeDates, isGenerating, setFilter, previousReport, defaultFilter}) {
    const [selected, setSelected] = React.useState("total");
    const {data, units, onUnitChange, isLoading} = useUnitComplianceChart({
        rangeDates,
        isGenerating,
        setFilter,
        previousReport,
        key: 'uic_response',
        defaultFilter,
    });

    return (<>
        <div className="mb-3">
            <p className="mb-4 text-3xl font-bold text-blue-950">Units In Compliance</p>
        </div>
        <div className={"flex flex-row items-end mb-2 gap-6"}>
            <div
                className="w-4/5 border-solid border-1 border-gray rounded-lg px-6 py-6">
                <span>Total number of units in compliance:</span>
                <div className="flex flex-row">
                    {(isLoading || isGenerating) && (
                        <>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'animate-pulse h-[20px] w-[95px] mr-[60px] bg-skeleton rounded-lg'}></Skeleton>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'animate-pulse h-[20px] w-[95px] bg-skeleton rounded-lg'}></Skeleton>
                        </>
                    )}
                    {!(isLoading || isGenerating) && (
                        <>
                             <span
                                 className="font-semibold text-2xl w-56">{data.in_compliance} / {data.total}</span><span
                            className="font-semibold text-2xl">{data.in_percentage}%</span>
                        </>
                    )}
                </div>
            </div>
            <div className="w-1/4">
                <Tabs
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                    size={"lg"}
                    color={"primary"}
                    classNames={{
                        base: "w-100",
                        tabList: "w-100"
                    }}
                >
                    <Tab key="total" title="Total" className={'text-lg'}></Tab>
                    <Tab key="segmented" title="Segmented" className={'text-lg'}></Tab>
                </Tabs>
                <UnitTypeFilter onChange={onUnitChange} value={units}></UnitTypeFilter>
            </div>
        </div>
        {(isLoading || isGenerating) && (
            <Skeleton isLoaded={false}
                      className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '100%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                    </ResponsiveContainer>
                </div>
            </Skeleton>
        )}
        {!(isLoading || isGenerating) && (
            <div className="flex flex-row border-solid border-1 border-gray rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '97%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                        <AreaChart data={data.chart} barGap={2}>
                            <CartesianGrid stroke="#e6e6e6" vertical={false}/>
                            <XAxis
                                angle={-35}
                                dataKey="log_date"
                                tick={{fontSize: 14}}
                                tickMargin={10}
                                tickSize={10}
                                height={100}
                            />
                            <YAxis domain={[0, 100]} padding={{top: 20}} tickFormatter={addPercentSymbol}/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>
                            <ReferenceLine y={0} stroke="#000"/>
                            {/*<Brush y={330} dataKey="name" height={30} stroke="#8884d8" />*/}
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#582AC6" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#582AC6" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#57BADF" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#57BADF" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            {selected === 'segmented' && (
                                <>
                                    <Area
                                        type="monotone"
                                        dataKey="percentage_driver_in_compliance"
                                        name="Driver"
                                        stroke="#582AC6"
                                        fillOpacity={0.9}
                                        fill="url(#colorUv)"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="percentage_truck_in_compliance"
                                        name="Truck"
                                        stroke="#57BADF"
                                        fillOpacity={0.9}
                                        fill="url(#colorPv)"
                                    />
                                </>
                            )}

                            {selected === 'total' && (
                                <Area
                                    type="monotone"
                                    dataKey="percentage_in_compliance"
                                    name="Total"
                                    stroke="#582AC6"
                                    fillOpacity={0.9}
                                    fill="url(#colorUv)"
                                />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )}
    </>);
}
