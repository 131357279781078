import React, {PureComponent, useMemo, useState, useEffect} from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Legend,
    Bar,
    Rectangle,
    Brush,
    ReferenceLine,
} from 'recharts';
import {Button, Checkbox, CheckboxGroup, Popover, PopoverContent, PopoverTrigger, Tab, Tabs} from "@nextui-org/react";
import DocumentTypeFilter, {DOCUMENT_FILTERS} from "../_shared/DocumentTypeFilter";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import {map, sumBy} from "lodash";
import {Skeleton} from "@nextui-org/skeleton";

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length > 0) {
        const {
            label = null,
            log_date,
            percentage_driver_in_compliance,
            percentage_truck_in_compliance,
            percentage_in_compliance
        } = payload[0]?.payload ?? null;

        return (
            <div
                className="custom-tooltip text-tiny mb-2"
                style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                }}
            >
                <p className="label font-semibold mb-2">{label}</p>
                <p className={'mb-2'}>{`Date: ${label ?? log_date}`}</p>
                <p className={'mb-2'}>{`Driver Docs: ${percentage_driver_in_compliance}%`}</p>
                <p className={'mb-2'}>{`Truck Docs: ${percentage_truck_in_compliance}%`}</p>
                <p>{`Total Docs: ${percentage_in_compliance}%`}</p>
            </div>
        );
    }

    return null;
};

const addPercentSymbol = (value) => `${value}%`;

let fetchDocsTimeout = null;

export default function DocumentsInComplianceChart({rangeDates, isGenerating, setDocumentInCompliance, previousReport, defaultFilter}) {
    const [selected, setSelected] = React.useState("total");
    const [documentIds, setDocumentIDs] = useState(DOCUMENT_FILTERS);
    const [documentsChart, setDocumentsChart] = useState(null);
    const [total, setTotal] = useState(0);
    const [inCompliance, setInCompliance] = useState(0);
    const [isLoading, setIsLoading] = useState(0);

    const fetchDocumentsCharts = async (docs) => {
        setIsLoading(true);

        const {response: documents} = await LapseReportApi.documentsInAndOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            documentIds: docs ?? [],
            previousReportId: previousReport,
            key: 'dic_response',
        });

        setTotal(sumBy(map(documents, item => {
            item.total = !!item.total ? Number(item.total) : 0;

            return item;
        }), 'total'));
        setInCompliance(sumBy(map(documents, item => {
            item.in_compliance = !!item.in_compliance ? Number(item.in_compliance) : 0;

            return item
        }), 'in_compliance'));
        setDocumentsChart(documents);

        setIsLoading(false);
    };

    useEffect(() => {
        if (isGenerating === false) {
            fetchDocumentsCharts(documentIds);
        }

        setDocumentInCompliance(documentIds)
    }, [isGenerating]);

    useEffect(() => {
        if (previousReport) fetchDocumentsCharts(documentIds);
    }, [previousReport]);

    useEffect(() => {
        if (defaultFilter) {
            setDocumentIDs(defaultFilter)
            setDocumentInCompliance(defaultFilter)
        };
    }, [defaultFilter]);


    const onDocumentChange = (docs) => {
        setDocumentIDs(docs);
        setDocumentInCompliance(docs);

        if (fetchDocsTimeout) {
            clearTimeout(fetchDocsTimeout)
        }

        fetchDocsTimeout = setTimeout(() => {
            fetchDocumentsCharts(docs)
        }, 1000)
    };

    const calculatePercentage = () => {
        const percentage = ((inCompliance / total) * 100).toFixed(2);

        if (isNaN(percentage)) {
            return 0;
        }

        return percentage;
    }

    return (<>
        <div className="mb-3">
            <p className="mb-4 text-3xl font-bold text-blue-950">Documents In Compliance</p>
        </div>
        <div className={"flex flex-row items-end mb-2 gap-6"}>
            <div className="w-4/5 border-solid border-1 border-gray rounded-lg px-6 py-6">
                <span>Total number of documents in compliance:</span>
                <div className="flex flex-row">
                    {(isLoading || isGenerating) && (
                        <>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'h-[20px] w-[95px] mr-[60px] bg-skeleton rounded-lg'}></Skeleton>
                            <Skeleton isLoaded={!(isLoading || isGenerating)}
                                      class={'h-[20px] w-[95px] bg-skeleton rounded-lg'}></Skeleton>
                        </>
                    )}
                    {!(isLoading || isGenerating) && (
                        <>
                            <span className="font-semibold text-2xl w-56">{inCompliance} / {total}</span>
                            <span
                                className="font-semibold text-2xl">{calculatePercentage()}%</span>
                        </>
                    )}
                </div>
            </div>
            <div className="w-1/4">
                <Tabs
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                    size={"lg"}
                    color={"primary"}
                    classNames={{
                        base: "w-100",
                        tabList: "w-100"
                    }}
                >
                    <Tab key="total" title="Total" className={'text-lg'}></Tab>
                    <Tab key="segmented" title="Segmented" className={'text-lg'}></Tab>
                </Tabs>
                <DocumentTypeFilter onChange={onDocumentChange} value={documentIds}/>
            </div>
        </div>
        { (isLoading || isGenerating) && (
            <Skeleton isLoaded={false}
                      className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '100%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                    </ResponsiveContainer>
                </div>
            </Skeleton>
        )}
        {!(isLoading || isGenerating) && (
            <div className="flex flex-row border-solid border-1 border-gray rounded-lg pl-2 pb-2 pt-8 pr-8">
                <div style={{width: '97%'}}>
                    <ResponsiveContainer width="100%" height={400}>
                        <AreaChart data={documentsChart} barGap={2}>
                            <CartesianGrid vertical={false}/>
                            <XAxis
                                angle={-35}
                                dataKey="log_date"
                                tick={{fontSize: 14}}
                                tickMargin={10}
                                tickSize={10}
                                height={100}
                            />
                            <YAxis domain={[0, 100]} padding={{top: 20}} tickFormatter={addPercentSymbol}/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>
                            <ReferenceLine y={0} stroke="#000"/>
                            {/*<Brush y={330} dataKey="name" height={30} stroke="#8884d8" />*/}
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#582AC6" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#582AC6" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#57BADF" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#57BADF" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            {selected === 'segmented' && (
                                <>
                                    <Area
                                        type="monotone"
                                        dataKey="percentage_driver_in_compliance"
                                        name="Driver"
                                        stroke="#582AC6"
                                        fillOpacity={0.9}
                                        fill="url(#colorUv)"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="percentage_truck_in_compliance"
                                        name="Truck"
                                        stroke="#57BADF"
                                        fillOpacity={0.9}
                                        fill="url(#colorPv)"
                                    />
                                </>
                            )}

                            {selected === 'total' && (
                                <Area
                                    type="monotone"
                                    dataKey="percentage_in_compliance"
                                    name="Total"
                                    stroke="#582AC6"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )}
    </>);
}
