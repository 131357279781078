import {DateRangePicker, Button, useDisclosure} from "@nextui-org/react";
import {MdOutlineLibraryBooks, MdSave} from "react-icons/md";
import {VscRunAll, VscSave, VscShare} from "react-icons/vsc";
import {BiSolidFileExport, BiSolidSave} from "react-icons/bi";
import {useEffect, useState} from "react";
import LapseReportApi from "../../http/apis/lapse-report-api";
import {getLocalTimeZone} from "@internationalized/date";
import PageUtils from "./utils/page-utils";
import SaveReport from "./previous-report/SaveReport";
import PreviousReport from "./previous-report/PreviousReport";
import moment from "moment";
import ShareModal from "./share/ShareModal";
import {useAtomValue} from "jotai/index";
import {previousReportAtom} from "./_atoms/lapseReportAtom";

export default function LapseReportHeading({rangeDates, setRangeDates, filterReport, isRunning, isGenerating, generate, isShare, filters, setPreviousReport, setDefaultFilters, setLapseReportRangeDates}) {
    const [isSharing, setIsSharing] = useState(false);
    const {isOpen: isOpenSave, onOpen: onOpenSave, onOpenChange: onOpenChangeSave} = useDisclosure();
    const {isOpen: isOpenPrev, onOpen: onOpenPrev, onOpenChange: onOpenChangePrev} = useDisclosure();
    const {isOpen: isOpenShare, onOpen: onOpenShare, onOpenChange: onOpenChangeShare} = useDisclosure();
    const [isDateValid, setIsDateValid] = useState(true);
    const [shareUrl, setShareUrl] = useState(null);
    const previousReport = useAtomValue(previousReportAtom)

    const share = async () => {
        setIsSharing(true);

        const {response: {url}} = await LapseReportApi.share({
            startDate: rangeDates.start.toDate(getLocalTimeZone()),
            endDate: rangeDates.end.toDate(getLocalTimeZone()),
        });

        window.navigator.clipboard.writeText(url)
        toastr.success("Share Link Copied to Clipboard", {timeOut: 1000})

        setShareUrl(url);
        setIsSharing(false);
        onOpenShare(true);
    };

    const onChangeDate = (value) => {
        setIsDateValid(value !== null && value.end.toDate() > value.start.toDate())
        setRangeDates(value)
    }

    return (
        <>
            <div className="flex w-70">
                <div className="head-title-theme">
                    <p>Lapse Report - Dashboard</p>
                    <h1>
                        Lapse Report
                    </h1>
                </div>
            </div>
            {isShare === false && (<Button
                color="default"
                className="h-[3em] md:w-[24em] md:px-[.5em] rounded-xl px-11 md:mr-5 text-white font-semibold"
                startContent={
                    <MdOutlineLibraryBooks size={40} className={'text-foreground-500'}/>
                }
                onClick={onOpenPrev}
            >
                <span className={'text-foreground-500'}>Previous Reports</span>
            </Button>)}
            {isShare === false && (<Button
                color="primary"
                className="h-[3em] md:w-[5em] text-white font-semibold md:mr-5 mb-2"
                startContent={
                    <BiSolidSave size={40} color={'white'}/>
                }
                isDisabled={!isDateValid}
                onClick={onOpenSave}
            >
            </Button>)}
            <div className={'flex w-70 justify-end'}>
                <DateRangePicker
                    visibleMonths={2}
                    className={'md:max-w-lg mb-2'}
                    classNames={{
                        inputWrapper: 'h-16',
                        selectorIcon: 'text-2xl',
                    }}
                    isDisabled={isShare}
                    value={rangeDates}
                    onChange={onChangeDate}
                />
                {isShare === false && (<Button
                    color="success"
                    className="h-[3em] md:w-[10em] text-white font-semibold md:ml-5 mb-2"
                    startContent={
                        <VscRunAll size={40} color={'white'}/>
                    }
                    onPress={generate}
                    isLoading={isGenerating}
                    isDisabled={!isDateValid}
                >
                    Run
                </Button>)}
                {isShare === false && (<Button
                    color="primary"
                    className="h-[3em] md:w-[5em] text-white font-semibold md:ml-5 mb-2"
                    startContent={
                        <VscShare size={40} color={'white'}/>
                    }
                    onPress={share}
                    isDisabled={!isDateValid || previousReport !== null}
                    isLoading={isSharing}
                >
                </Button>)}
                <SaveReport
                    isOpen={isOpenSave}
                    onOpen={onOpenSave}
                    onOpenChange={onOpenChangeSave}
                    rangeDates={rangeDates}
                    filters={filters}
                />
                <PreviousReport isOpen={isOpenPrev}
                                onOpen={onOpenPrev}
                                onOpenChange={onOpenChangePrev}
                                setPreviousReport={setPreviousReport}
                                setDefaultFilters={setDefaultFilters}
                                setRangeDates={setRangeDates}
                                setLapseReportRangeDates={setLapseReportRangeDates}/>
                <ShareModal
                    isOpen={isOpenShare}
                    onOpen={onOpenShare}
                    onOpenChange={onOpenChangeShare}
                    shareUrl={shareUrl}
                ></ShareModal>
            </div>
        </>
    );
}
