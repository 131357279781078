import {Chip, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import React from "react";

export default function UnitDetailsTable({unit}) {
    return (
        <>
            <Table isHeaderSticky classNames={{
                th: 'bg-default-100 py-[15px]',
                base: "max-h-[520px] overflow-hidden",
                td: 'py-[1rem]',
                wrapper: 'px-0'
            }}>
                <TableHeader>
                    <TableColumn>{unit.unit_type} ID</TableColumn>
                    <TableColumn>Name</TableColumn>
                    <TableColumn>Company</TableColumn>
                    <TableColumn>Current Status</TableColumn>
                    <TableColumn>Current Compliance</TableColumn>
                </TableHeader>
                <TableBody>
                    <TableRow key={1}>
                        <TableCell>{unit.display_id}</TableCell>
                        <TableCell>
                            {unit.unit_type === 'Truck' ? unit.vin : `${unit.first_name} ${unit.last_name}`}
                        </TableCell>
                        <TableCell>
                            <div className="flex flex-col">
                                <p className="font-semibold capitalize">{unit.company_name}</p>
                                <p className="mt-2 capitalize text-default-400">
                                    #{unit.company_id}
                                </p>
                            </div>
                        </TableCell>
                        <TableCell>
                            {
                                unit.latest_is_active === 0 && (
                                    <Chip
                                        className="capitalize h-[26px]"
                                        color="danger"
                                        size="md"
                                        variant="flat"
                                    >
                                        Inactive
                                    </Chip>
                                )
                            }
                            {
                                unit.latest_is_active === 1 && (
                                    <Chip
                                        className="capitalize h-[26px] w-[30px]"
                                        color="success"
                                        size="md"
                                        variant="flat"
                                    >
                                        Active
                                    </Chip>
                                )
                            }
                        </TableCell>
                        <TableCell>
                            {
                                unit.latest_is_compliant === 0 && (
                                    <div className="flex items-center">
                                        <div
                                            className={`h-3 w-3 mr-3 rounded-full bg-red-500`}
                                        ></div>
                                        <span
                                            className={`text-red-500`}
                                        >
                                                            Out of Compliance
                                                        </span>
                                    </div>
                                )
                            }
                            {
                                unit.latest_is_compliant === 1 && (
                                    <div className="flex items-center">
                                        <div
                                            className={`h-3 w-3 mr-3 rounded-full bg-green-500`}
                                        ></div>
                                        <span
                                            className={`text-green-500`}
                                        >
                                                            Compliant
                                                        </span>
                                    </div>
                                )
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    )
}
