import axios from "axios";
import ResponseData from "./response-data";
import {stringify} from "qs";
import PAGES from "../_constants/pages";
import PageUtils from "../components/lapse-report/utils/page-utils";

const httpClient = (() => {
    const clientInstance = axios.create();

    clientInstance.interceptors.request.use((request) => {
        const hash = PageUtils.getShareHash();

        if (hash) {
            request.headers['X-Share-Hash'] = hash;
        }

        return request;
    });

    clientInstance.interceptors.response.use((response) => {
        const {status, data} = response;

        return ResponseData({
            statusCode: status,
            response: data,
        });
    });

    return clientInstance;
})();


export default {
    /**
     * @param endpoint
     * @param queryParams
     *
     * @returns {Promise<ResponseData>}
     */
    get(endpoint, {queryParams = null, headers = {}} = {}) {
        return httpClient.get(`${endpoint}?${stringify(queryParams)}`, headers);
    },
    /**
     * @param endpoint
     * @param body
     * @param headers
     *
     * @returns {Promise<ResponseData>}
     */
    post(endpoint, body = {}, {headers = {}} = {}) {
        return httpClient.post(endpoint, body, {headers});
    },
    /**
     * @param endpoint
     * @param body
     * @param headers
     *
     * @returns {Promise<ResponseData>}
     */
    patch(endpoint, body = {}, {headers = {}} = {}) {
        return httpClient.patch(endpoint, body, {headers});
    },
    /**
     *
     * @param endpoint
     * @param body
     * @param headers
     *
     * @returns {Promise<ResponseData>}
     */
    put(endpoint, body = {}, {headers = {}} = {}) {
        return httpClient.put(endpoint, body, {headers});
    },
    /**
     *
     * @param endpoint
     * @param body
     * @param queryParams
     * @param headers
     *
     * @returns @returns {Promise<ResponseData>}
     */
    delete(endpoint, {body = {}, queryParams, headers = {}} = {}) {
        return httpClient.delete(endpoint, {data: body, headers});
    },
}
