import React from "react";
import {
    Chip,
    Table,
    Spinner,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip,
    Button
} from "@nextui-org/react";
import {EyeIcon} from "../../icons/EyeIcon";
import {MdUpload} from "react-icons/md";
import {GoUpload} from "react-icons/go";
import SkeletonTable from "../_shared/SkeletonTable";

export default function DocumentsMeasuredTable({isLoading, documents, isShare}) {
    const navigateTo = (url, isCompliant) => {
        if (isCompliant === 0) {
            return;
        }

        window.open(url, '_blank').focus();
    }

    return (
        <>
            <span className={'text-2xl text-bold'}>Documents Measured</span>
            {isLoading && <SkeletonTable rows={3}></SkeletonTable>}
            {isLoading === false && (
                <Table isHeaderSticky classNames={{
                    th: 'bg-default-100 py-[15px]',
                    base: "max-h-[520px] overflow-hidden",
                    td: 'py-[1rem]',
                    wrapper: 'px-0'
                }}>
                    <TableHeader>
                        <TableColumn>Document</TableColumn>
                        <TableColumn>Days out of Compliance</TableColumn>
                        <TableColumn>% of Compliance</TableColumn>
                        <TableColumn>Current Compliance</TableColumn>
                        <TableColumn hidden={isShare}>Actions</TableColumn>
                    </TableHeader>
                    <TableBody
                        isLoading={isLoading}
                        loadingContent={<Spinner label={'Loading...'}></Spinner>}
                    >
                        {
                            documents && documents.map((document, index) => (
                                <TableRow key={index}>
                                    <TableCell>{document.document_name}</TableCell>
                                    <TableCell>{document.days_out_of_compliance}</TableCell>
                                    <TableCell>{document.percentage_in_compliance}%</TableCell>
                                    <TableCell>
                                        {
                                            document.latest_is_compliant === 0 && (
                                                <div className="flex items-center">
                                                    <div
                                                        className={`h-3 w-3 mr-3 rounded-full bg-red-500`}
                                                    ></div>
                                                    <span
                                                        className={`text-red-500`}
                                                    >
                                                            Out of Compliance
                                                        </span>
                                                </div>
                                            )
                                        }
                                        {
                                            document.latest_is_compliant === 1 && (
                                                <div className="flex items-center">
                                                    <div
                                                        className={`h-3 w-3 mr-3 rounded-full bg-green-500`}
                                                    ></div>
                                                    <span
                                                        className={`text-green-500`}
                                                    >
                                                            Compliant
                                                        </span>
                                                </div>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell className={'d-flex flex-row'} hidden={isShare}>
                                        <div className="relative flex items-center ml-2 gap-5">
                                            <Tooltip
                                                content={`${document.latest_is_compliant ? 'View Document' : 'Missing'}`}
                                                className={`${document.latest_is_compliant ? 'text-default-900' : 'text-white bg-red-500'} py-3`}>
                                      <span
                                          className={`text-[1.3em] ${document.latest_is_compliant ? 'text-default-400' : 'text-red-500'} cursor-pointer active:opacity-50`}
                                          onClick={() => navigateTo(document.view_unit_document_url, document.latest_is_compliant)}>
                                        <EyeIcon/>
                                      </span>
                                            </Tooltip>
                                            {
                                                document.latest_is_compliant === 0 && (
                                                    <Tooltip content="Upload Document" className={`text-default-900 py-3`}>
                                                        <span
                                                            className={`text-[1.3em] text-default-500 cursor-pointer active:opacity-50`}
                                                            onClick={() => navigateTo(document.view_unit_document_url)}>
                                                          <GoUpload/>
                                                        </span>
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            )}
        </>
    );
};
