import {UNIT_TYPES} from "../../../_constants/unit-type";
import {Button, Checkbox, CheckboxGroup, Popover, PopoverContent, PopoverTrigger} from "@nextui-org/react";
import React, {useState} from "react";
import {useAtomValue} from "jotai/index";
import {previousReportIdAtom} from "../_atoms/lapseReportAtom";

export default function UnitTypeFilter({onChange, width = 'w-100', value = null, marginTop = 'mt-2'}) {
    const previousReportId = useAtomValue(previousReportIdAtom)
    value ??= UNIT_TYPES;

    const isDisabled = (name) => {
        return (value.length === 1 && value[0] === name) || previousReportId !== null;
    };

    return (
        <Popover placement="bottom" showArrow={true} size="sm">
            <PopoverTrigger>
                <Button
                    className={`${marginTop} min-w-0 text-base border-1 border-gray text-lg h-[35px] ${width}`}
                    color="primary"
                    size={"lg"}
                >
                    Unit Type
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <div className="px-1 py-2">
                    <CheckboxGroup
                        label={<p>Select Unit Types</p>}
                        onChange={onChange}
                        size={"lg"}
                        value={value}
                    >
                        <Checkbox value="driver" isDisabled={isDisabled('driver')}>Driver</Checkbox>
                        <Checkbox value="truck" isDisabled={isDisabled('truck')}>Truck</Checkbox>
                    </CheckboxGroup>
                </div>
            </PopoverContent>
        </Popover>
    );
}
