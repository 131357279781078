import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Divider} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {VscRunAll} from "react-icons/vsc";
import {getLocalTimeZone} from "@internationalized/date";
import moment from "moment";
import useShareReport from "../_hooks/useShareReport";
import LapseReportApi from "../../../http/apis/lapse-report-api";
import {filtersAtom} from "../_atoms/lapseReportAtom";
import {useAtom} from "jotai";


export default function SaveReport({isOpen, onOpen, onOpenChange, rangeDates, filters}) {
    const startDate = moment(rangeDates?.start?.toDate(getLocalTimeZone()));
    const startDateFmt = moment(rangeDates?.start?.toDate(getLocalTimeZone())).format('MM/DD/YYYY');
    const endDate =  moment(rangeDates?.end?.toDate(getLocalTimeZone()));
    const endDateFmt =  moment(rangeDates?.end?.toDate(getLocalTimeZone())).format('MM/DD/YYYY');
    const formattedDate = `${startDateFmt} - ${endDateFmt}`;
    const [remarks, setRemarks] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [nfilters] = useAtom(filtersAtom)

    const saveReport = async () => {
        setIsSaving(true);

        const {
            documentInCompliance: dicFilters,
            unitInCompliance: uicFilters,
            unitOutOfCompliance: uocFilters,
            calendarDocsOutOfCompliance: occDocumentFilters,
            calendarUnitsOutOfCompliance: occUnitFilters,
        } = filters;

        await LapseReportApi.saveReport({
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            remarks,
            docFilters: nfilters.doc_filters,
            dicFilters,
            uocFilters,
            uicFilters,
            occDocumentFilters,
            occUnitFilters,
            highAlertUnitFilters: nfilters.high_alert_unit_filters,
        });

        setIsSaving(false);

        toastr.success("Lapse Report has been saved", {timeOut: 1000});

        onOpenChange(false);
    };

    useEffect(() => {
        setRemarks(null)
    }, [isOpen]);

    return (
        <>
            <Modal
                size={'3xl'}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                isDismissable={false}
                isKeyboardDismissDisabled={true}
                classNames={{
                    closeButton: "border-none bg-white mt-4 mr-3",
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-2xl font-bold text-blue-950 pt-3 mt-3 pb-0">Save Lapse Report</ModalHeader>
                            <Divider className={'mb-3'}/>
                            <ModalBody>
                                <div className={'mb-3 flex flex-row gap-5'}>
                                    <p className={'text-bold'}>Lapse Report</p>
                                    <p className={'title'}>{formattedDate}</p>
                                </div>
                                <div>
                                    <p className={'text-bold'}>Add Remarks (Optional)</p>
                                    <input
                                        onChange={event => setRemarks(event.target.value)}
                                        placeholder="Enter remarks"
                                        className="form-control form-input rounded-lg h-16"
                                    />
                                </div>
                            </ModalBody>
                            <Divider className={'mb-3'}/>
                            <ModalFooter className={'justify-between'}>
                                <Button color="default"
                                        className={'float-left border border-gray text-lg h-[35px] drop-shadow-none'}
                                        variant="light"
                                        isDisabled={isSaving}
                                        onPress={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={isSaving}
                                    color="success"
                                    className={'border-none border-gray text-white text-lg h-[35px] drop-shadow-none'}
                                    onPress={saveReport}>
                                    Save Report
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>);
}
