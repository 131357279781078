import {atom} from "jotai/index";
import {DOCUMENT_FILTERS} from "../_shared/DocumentTypeFilter";
import {UNIT_TYPES} from "../../../_constants/unit-type";

// Atom is having issue when using module imports
const documentFilters = [
    "d_driver_license",
    "d_annual_mvr",
    "d_medical_examiner",
    "t_registration",
    "t_annual_inspection",
    "t_truck_insurance_liability",
];
const Unit = require('../../../_constants/unit-type');

export const previousReportIdAtom = atom(null)
export const previousReportAtom = atom(null)

export const filtersAtom = atom({
    dic_filters: documentFilters,
    doc_filters: documentFilters,
    uic_filters: Unit.UNIT_TYPES,
    uoc_filters: Unit.UNIT_TYPES,
    occ_document_filters: Document.DOCUMENT_FILTERS,
    occ_unit_filters: Unit.UNIT_TYPES,
    high_alert_unit_filters: {
        search_text: null,
        units: Unit.UNIT_TYPES,
        percentage: 50,
        status: [1, 0],
        compliant: [1, 0],
    }
})
