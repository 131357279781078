import PAGES from "../../../_constants/pages";

export default {
    getShareHash() {
        const url = document.location.href;
        const hasHash = url.includes(PAGES.LAPSE_REPORT.SHARE);

        if (!hasHash) {
            return null;
        }

        const hash = url.split('/').pop();

        return hash;
    }
}
