import {MdInfo} from "react-icons/md";
import {VscRunAll} from "react-icons/vsc";
import {useAtomValue} from "jotai";
import {previousReportAtom} from "../_atoms/lapseReportAtom";

export default function PreviousLapseReportAlert() {
    const previousReport = useAtomValue(previousReportAtom)

    return (
        <>
            {previousReport !== null && (
                <div
                    className="flex items-center p-4 text-blue-800 mb-10 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
                    role="alert">
                    <MdInfo color={'primary'} size={20}/>
                    <div>
                        <span
                            className="ml-3 font-medium">Previous Lapse Report for {previousReport.lapse_report}</span> is
                        currently displayed, To adjust any filters, please <span
                        className={'font-medium'}> Run </span> the
                        report again.
                    </div>
                </div>
            )}
        </>
    )
}
