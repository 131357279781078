import React, {useEffect, useRef, useState, useMemo} from 'react';
import {
    Tab,
    Tabs,
    Popover,
    PopoverContent,
    PopoverTrigger,
    CheckboxGroup,
    Checkbox,
    Button,
} from "@nextui-org/react";
import {
    ResponsiveContainer
} from 'recharts';
import CalHeatmap from 'cal-heatmap';
import Legend from 'cal-heatmap/plugins/Legend';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import 'cal-heatmap/cal-heatmap.css';
import LapseReportApi from "../../../http/apis/lapse-report-api";
import DocumentTypeFilter, {DOCUMENT_FILTERS} from "../_shared/DocumentTypeFilter";
import UnitTypeFilter from "../_shared/UnitTypeFilter";
import {groupBy, maxBy, minBy} from "lodash";
import moment from "moment";
import {Skeleton} from "@nextui-org/skeleton";
import {UNIT_TYPES} from "../../../_constants/unit-type";
import CalendarHeatmap from "./CalendarHeatmap";

let calendar = null;
let apiCallTimeout = null;

export default function OutOfComplianceCalendarChart({
     rangeDates,
     isGenerating,
     setCalendarDocsOutOfCompliance,
     setCalendarUnitsOutOfCompliance,
     previousReport,
     docFilter,
     unitFilter,
}) {
    const [selected, setSelected] = useState('documents');
    const heatmapContainerRef = useRef(null);
    const [documentIds, setDocumentIDs] = useState(DOCUMENT_FILTERS);
    const [unitIds, setUnitIds] = useState(UNIT_TYPES);
    const [isLoading, setIsLoading] = useState(null);
    const [previousReportCopy, setPreviousReportCopy] = useState(null);
    const [heatmap, setHeatmap] = useState({
        calendars: [],
        domain: null,
        label: null,
        hasLegend: true,
        max: 0,
    })

    useEffect(() => {
        setPreviousReportCopy(null);
    }, [isGenerating]);

    useEffect(() => {
        if (previousReport) {
            setPreviousReportCopy(previousReport);
            setSelected('documents');
            fetchDocuments(documentIds, previousReport);
        }
    }, [previousReport]);

    useEffect(() => {
        if (docFilter && unitFilter) {
            setDocumentIDs(docFilter);
            setUnitIds(unitFilter);
            setCalendarUnitsOutOfCompliance(unitFilter);
            setCalendarDocsOutOfCompliance(docFilter);
        }
    }, [docFilter, unitFilter]);

    const mappedResult = (response, date_format = 'MM/DD/YYYY') => {
        const result = response.map((item) => {
            item.log_date = moment(item.log_date, date_format).format('YYYY-MM-DD');
            item.unix = moment(item.log_date).unix()
            item.year = moment(item.log_date).year()
            item.out_of_compliance = Number(item.out_of_compliance ?? 0);

            return item;
        });
        const max = maxBy(response, 'out_of_compliance')?.out_of_compliance ?? 0;

        // Let's group the calendars by year
        let calendars = Object.values(groupBy(result, 'year'));

        calendars = calendars.map(days => {
            // Now calculate the `range` of each group by getting the month difference [(maxMonth - minMonth) + 1]
            const minDay = moment(minBy(days, 'unix').log_date)
            const range = (moment(maxBy(days, 'unix').log_date).month() - minDay.month()) + 1
            const year = days[0].year;
            const start = minDay.startOf('month').format('YYYY-MM-DD')

            return {
                range,
                year,
                days,
                start: new Date(start),
            }
        })

        return {
            result,
            max,
            calendars,
        }
    }

    const fetchDocuments = async (docs, previousReportCopy) => {
        setIsLoading(true);

        const {response} = await LapseReportApi.documentsInAndOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            documentIds: docs ?? [],
            ignoreGroupings: 1,
            previousReportId: previousReportCopy,
            key: 'occ_document_response',
        });

        await drawCalendar({
            label: "Number of Documents Missing",
            mapped: mappedResult(response),
            selected: 'documents',
        });

        setIsLoading(false);
    };

    const fetchUnits = async (unitIds) => {
        setIsLoading(true);

        const {response} = await LapseReportApi.unitsInAndOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            units: unitIds ?? [],
            ignoreGroupings: 1,
            previousReportId: previousReportCopy,
            key: 'occ_unit_response'
        });

        await drawCalendar({
            label: "Number of Units out of compliance",
            mapped: mappedResult(response),
            selected: 'units',
        });

        setIsLoading(false);
    }

    const fetchDays = async (selected) => {
        setIsLoading(true);

        const {response} = await LapseReportApi.daysOutOfCompliance({
            startDate: rangeDates.start,
            endDate: rangeDates.end,
            previousReportId: previousReportCopy,
        });

        await drawCalendar({
            label: "Number of Days out of compliance",
            mapped: mappedResult(response, null),
            domain: [0, 1],
            hasLegend: false,
            selected: 'days',
        });

        setIsLoading(false);
    }

    const onDocumentChange = (docs) => {
        setPreviousReportCopy(null);
        setDocumentIDs(docs);
        setCalendarDocsOutOfCompliance(docs);

        if (apiCallTimeout) {
            clearTimeout(apiCallTimeout)
        }

        apiCallTimeout = setTimeout(() => {
            fetchDocuments(docs)
        }, 1000)
    };

    const onUnitChange = (units) => {
        setPreviousReportCopy(null);
        setUnitIds(units);
        setCalendarUnitsOutOfCompliance(units);

        if (apiCallTimeout) {
            clearTimeout(apiCallTimeout)
        }

        apiCallTimeout = setTimeout(() => {
            fetchUnits(units)
        }, 1000)
    }

    const drawCalendar = async ({
        label,
        mapped,
        domain = null,
        hasLegend = true,
        selected,
    }) => {
        setHeatmap({
            calendars: mapped.calendars ?? [],
            label,
            hasLegend,
            domain,
            max: mapped.max,
        });

        if (calendar) await calendar.destroy();

        calendar = new CalHeatmap();

        let plugins = [];

        if (hasLegend) {
            plugins = [
                [
                    Legend,
                    {
                        label,
                        width: 400,
                    },
                ],
            ];
        }

        const tooltipUnit = (value, date) => {
            let unitTitle = null;

            if (selected === 'documents') {
                unitTitle = `Total Documents: ${value ?? 0}`
            }

            if (selected === 'units') {
                unitTitle = `Total Units: ${value ?? 0}`
            }

            if (selected === 'days') {
                unitTitle = value === 1 ? 'Out of Compliance' : 'In Compliance'
            }

            return `
                <div>Date: ${date}</div>
                <div>${unitTitle}</div>
            `;
        };

        setTimeout(() => {
            calendar.paint({
                    itemSelector: heatmapContainerRef.current,
                    date: {start: new Date(`${moment().year()}-01-01`)},
                    range: 12,
                    domain: {
                        type: 'month',
                        sort: 'asc',
                        gutter: 10
                    },
                    subDomain: {type: "day", radius: 2, width: 15, height: 15},
                    data: {
                        source: [],
                        x: 'log_date',
                        y: 'out_of_compliance',
                    },
                    scale: { color: { type: 'quantize', scheme: 'Blues', domain: domain ?? [0, mapped.max] } },
                },
                [...plugins, [
                    Tooltip,
                    {
                        enabled: true,
                        text: (timestamp, value, dayjsDate) => {
                            const date = moment(dayjsDate.$d).utc().format('MM/DD/YYYY');

                            return tooltipUnit(value, date);
                        },
                        placement: 'auto',
                    }
                ]]
            );
        }, 200)
    };

    const onTabChange = (tabItem) => {
        if (tabItem === selected) {
            return;
        }

        setSelected(tabItem);
        loadCalendar(tabItem);
    }

    const loadCalendar = (tabItem) => {
        if (tabItem === 'documents') {
            return fetchDocuments(documentIds, previousReportCopy);
        }

        if (tabItem === 'units') {
            return fetchUnits(unitIds);
        }

        if (tabItem === 'days') {
            return fetchDays();
        }
    }

    useEffect(() => {
        if (isGenerating === false) {
            loadCalendar(selected);
        }

        setCalendarUnitsOutOfCompliance(unitIds);
        setCalendarDocsOutOfCompliance(documentIds);
    }, [isGenerating]);


    return (
        <>
            <div className="mb-3">
                <p className="mb-4 text-3xl font-bold text-blue-950">Out of Compliance Calendar</p>
            </div>
            <div className={"flex flex-row items-end mb-2 gap-6"}>
                <Tabs
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={onTabChange}
                    size={"lg"}
                    color={"primary"}
                    classNames={{
                        tabList: 'p-1 bg-[#F2F2F2]'
                    }}
                    isDisabled={isLoading}
                >
                    <Tab key="documents" title="Documents" className={'text-lg'}></Tab>
                    <Tab key="units" title="Units" className={'text-lg'}></Tab>
                    <Tab key="days" title="Days" className={'text-lg'}></Tab>
                </Tabs>
                {selected === 'documents' && <DocumentTypeFilter onChange={onDocumentChange} width={null} value={documentIds}/>}
                {selected === 'units' && <UnitTypeFilter onChange={onUnitChange} width={null} value={unitIds}/>}
            </div>
            { (isLoading || isGenerating) && (
                <Skeleton isLoaded={false}
                          className="animate-pulse flex flex-row border-solid border-1 border-gray bg-skeleton rounded-lg pl-3 pb-2 pt-8 pr-8 mt-2 min-h-[22rem]">
                    <div style={{width: '100%'}}>
                        <ResponsiveContainer width="100%" height={400}>
                        </ResponsiveContainer>
                    </div>
                </Skeleton>
            )}

            {!(isLoading || isGenerating) && (
                <div
                    className="flex flex-row border-solid border-1 border-gray rounded-lg pl-3 pb-2 pt-8 pr-8 mt-2 min-h-[22rem] overflow-y-scroll">
                    <div >
                        <div className={'flex gap-2'}>
                            {heatmap.calendars.length === 0 && (
                                <div id='cal-heatmap' ref={heatmapContainerRef}>
                                    <div className='mb-3 w-100 bg-[#EDEDED] text-center'>
                                        <text className="font-bold text-[10px] p-3">{moment().year()}</text>
                                    </div>
                                </div>
                            )}
                            {heatmap.calendars.map((item, index) => (
                                <CalendarHeatmap
                                    days={item.days}
                                    start={item.start}
                                    max={heatmap.max}
                                    year={item.year}
                                    range={item.range}
                                    selected={selected}
                                    hasLegend={heatmap.hasLegend && index === 0}
                                    domain={heatmap.domain}
                                    label={heatmap.label}
                                >
                                </CalendarHeatmap>
                            ))}
                        </div>
                        <div id="calendar-legend"></div>
                        {
                            selected === 'days' && (
                                <div className={'flex flex-row gap-3'}>
                                    <div>
                                        <div className="h-4 w-100 bg-[#EFF3FF]"></div>
                                        <text className="font-bold text-[10px] pb-3">In Compliance</text>
                                    </div>
                                    <div>
                                        <div className="h-4 w-100 bg-[#08519c]"></div>
                                        <text className="font-bold text-[10px] pb-3">Out of compliance</text>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            )}
        </>
    )
}
