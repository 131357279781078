import LapseReportPage from "./lapse-report/LapseReportPage";
import Landing from "./Landing";
import { createRoot } from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import Thankyou from "./Thankyou";
import ShortLandingPage from "./ShortLandingPage";

// Existing Lapse Report code
const lapseReportElem = document.getElementById("lapse-report");
if (lapseReportElem) {
    const root = createRoot(lapseReportElem);
    root.render(
        <NextUIProvider>
            <LapseReportPage />
        </NextUIProvider>
    );
}

// Add Landing page mount
const landingElem = document.getElementById("fleet-optimization");
if (landingElem) {
    const root = createRoot(landingElem);
    root.render(
        <NextUIProvider>
            <Landing />
        </NextUIProvider>
    );
}

// Add Short Landing page mount
const shortLandingElem = document.getElementById("compliance-tracking");
if (shortLandingElem) {
    const root = createRoot(shortLandingElem);
    root.render(
        <NextUIProvider>
            <ShortLandingPage />
        </NextUIProvider>
    );
}

// Add Landing page mount
const thankyouElem = document.getElementById("thankyoupage");
if (thankyouElem) {
    const root = createRoot(thankyouElem);
    root.render(
        <NextUIProvider>
            <Thankyou />
        </NextUIProvider>
    );
}
